import { lazy } from 'react';
import FAQPage from '../../volunteers/pages/FAQPage';

import HomePage from '../../volunteers/pages/HomePage';
import WorkPage from '../../volunteers/pages/WorkPage';
const SummaryPage = lazy(() => import('../../volunteers/pages/SummaryPage'));
// const DashboardPage = lazy(() => import('../pages/DashboardPage'));
const RegistrationPage = lazy(
  () => import('../../volunteers/pages/RegistrationPage'),
);
const LoginPage = lazy(() => import('../../volunteers/pages/LoginPage'));
const ForgetPassPage = lazy(() => import('../pages/ForgetPassPage'));
const PassRecoveryPage = lazy(() => import('../pages/PassRecoveryPage'));
const NotFoundPage = lazy(() => import('../pages/NotFoundPage'));
const SocialAuthPage = lazy(
  () => import('../../volunteers/pages/SocialAuthPage'),
);
const CabinetPage = lazy(() => import('../../volunteers/pages/Cabinet'));
const ChangeLoginPage = lazy(
  () => import('../../volunteers/pages/ChangeLoginPage'),
);
const ChangePassPage = lazy(
  () => import('../../volunteers/pages/ChangePassPage'),
);
// TODO: проверить в каждом роуте пропсу className / direction - если не нужна - выпилить
// TODO: в какойто момент происходит переход не на /summary?step-..
// а на /?step-.. - ПОФИКСИТЬ
/**
 * @param formattedTitle ключ перевода который прилетает в <Layout -> <Helmet -> title
 */
const routesConfig = [
  {
    type: 'private',
    path: '/',
    exact: true,
    component: SummaryPage,
    formattedTitle: 'pages.dashboard.title',
    className: 'dashboard-page',
  },
  {
    type: 'private',
    path: '/summary',
    exact: true,
    component: SummaryPage,
    formattedTitle: 'pages.dashboard.title',
    className: 'summary-page',
  },
  {
    type: 'private',
    path: '/cabinet',
    exact: false,
    component: CabinetPage,
    formattedTitle: 'pages.cabinet.title',
    className: 'cabinet-page',
  },
  {
    type: 'private',
    path: '/cabinet/work',
    exact: true,
    component: WorkPage,
    formattedTitle: 'pages.cabinet.title',
    className: 'cabinet-page',
  },
  {
    type: 'private',
    path: '/cabinet/faq',
    exact: true,
    component: FAQPage,
    formattedTitle: 'pages.cabinet.title',
    className: 'cabinet-page',
  },

  {
    type: 'public',
    path: '/home',
    exact: true,
    component: HomePage,
    formattedTitle: 'pages.home.title',
    className: 'home-page full-height',
    direction: 'column',
  },
  {
    type: 'public',
    path: '/register',
    exact: true,
    component: RegistrationPage,
    formattedTitle: 'pages.register.title',
    className: 'register-page full-height',
    direction: 'column',
  },
  {
    type: 'public',
    path: '/login',
    exact: true,
    component: LoginPage,
    formattedTitle: 'pages.login.title',
    className: 'login-page full-height',
    direction: 'column',
  },
  {
    type: 'private',
    path: '/login-change',
    exact: true,
    component: ChangeLoginPage,
    formattedTitle: 'pages.change-login.title',
    className: 'login-page full-height',
    direction: 'column',
  },
  {
    type: 'private',
    path: '/pass-change',
    exact: true,
    component: ChangePassPage,
    formattedTitle: 'pages.change-pass.title',
    className: 'login-page full-height',
    direction: 'column',
  },
  {
    type: 'public',
    path: '/forget-pass',
    exact: true,
    component: ForgetPassPage,
    formattedTitle: 'pages.forget-pass.title',
    className: 'forget-pass-page full-height',
    direction: 'column',
  },
  {
    type: 'public',
    path: '/password-recovery/:token?',
    exact: true,
    component: PassRecoveryPage,
    formattedTitle: 'pages.pass-recov.title',
    className: 'pass-recov-page full-height',
    direction: 'column',
  },
  {
    type: 'public',
    path: '/(facebook|vk|google)/redirect',
    component: SocialAuthPage,
    formattedTitle: 'pages.social-auth.title',
    className: 'social-auth-page',
  },
  {
    type: 'public',
    path: '*',
    exact: true,
    component: NotFoundPage,
    formattedTitle: 'pages.not-found.title',
    className: 'not-found-page',
  },
];

export default routesConfig;
