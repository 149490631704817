import { volunteerConstants } from '../constants/volunteer';
import { history } from '../store/configureStore';
/**
 * @description слушаем логаут и выходим на главную
 */
const logoutMiddleware = () => (next) => (action) => {
  const result = next(action);
  if (action.type === volunteerConstants.LOGOUT_SUCCESS) {
    history.push('/home');
  }
  return result;
};

export default logoutMiddleware;
