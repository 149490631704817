import { Test } from '../../volunteers/pages/TestingPage';
import * as testConstant from '../constants/testConstants';

const initialState = {
  selectTest: null,
  selectedTest: null,
  testsList: [],
  testsInfo: null,
  loading: false,
  lastResult: null,
};

export type TestActions = {
  type: string;
  payload?: any;
};

export interface TestState {
  selectTest: number | null | undefined;
  selectedTest: Test | null;
  testsList: Test[];
  testsInfo: any;
  loading: boolean;
  lastResult: number | null;
}

export default (
  state: TestState = initialState,
  actions: TestActions,
): TestState => {
  switch (actions.type) {
    case testConstant.SELECT_TEST:
      return {
        ...state,
        selectTest: actions.payload,
      };
    case testConstant.REMOVE_TEST:
      return {
        ...state,
        selectedTest: null,
      };
    case testConstant.SET_SINGLE_TEST:
      return {
        ...state,
        selectedTest: actions.payload,
      };
    case testConstant.SET_TESTS_LIST:
      return {
        ...state,
        testsList: actions.payload,
      };
    case testConstant.SET_TESTS_INFO:
      return {
        ...state,
        testsInfo: actions.payload,
      };
    case testConstant.SEND_TEST: {
      const result =
        actions.payload?.testResults[actions.payload?.testResults.length - 1]
          ?.result;
      return {
        ...state,
        lastResult: result || 0,
        selectedTest: null,
      };
    }
    case testConstant.SEND_TEST_FAILED:
      return {
        ...state,
        lastResult: null,
        selectedTest: null,
      };
    case testConstant.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case testConstant.END_LOADING:
      return {
        ...state,
        loading: false,
      };
    case testConstant.CLEAN_RESULT:
      return {
        ...state,
        lastResult: null,
      };
    default:
      return {
        ...state,
      };
  }
};
