import { getAuthToken } from '@utils/utils';

/**
 * @description дергается в Public/Private роутах для проверки залогиненности
 * @returns флаг и функцию видимости
 */
const useStoreAuth = (store) => {
  const state = store.getState();
  const {
    volunteer: { isAuthenticated },
  } = state;

  const isAuth = isAuthenticated && getAuthToken();
  return isAuth;
};

export default useStoreAuth;
