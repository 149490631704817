import { normalizeResumeOnGet } from '../helpers/normalizeResumeOnGet';
import { normalizeResumeOnSave } from '../helpers/normalizeResumeOnSave';
import { req } from '../setup';
// import { normalizeResumeOnGet } from '@api/helpers/normalizeResumeOnGet';
// import { normalizeResumeOnSave } from '@api/helpers/normalizeResumeOnSave';

const resume = {
  get(): Promise<any> {
    return req.get('/Employee').then((res) => normalizeResumeOnGet(res.data));
  },
  edit(data: any): Promise<any> {
    return req
      .put('/Employee', normalizeResumeOnSave(data))
      .then((res) => normalizeResumeOnGet(res.data));
  },
  save(data: any): Promise<any> {
    return req
      .post('/Employee', normalizeResumeOnSave(data))
      .then((res) => normalizeResumeOnGet(res.data));
  },
  publish(data: any): Promise<any> {
    return req
      .put('/Employee/publish', normalizeResumeOnSave(data))
      .then((res) => normalizeResumeOnGet(res.data));
  },
  /**
   * @description отдельная загрузка файлов в отрыве от редакс формы
   * @param {*} data файл
   * @param {*} type название поля которое изменяем
   * @returns объект - там нужный ключ 'url'
   */
  upload(data: any, type: any): Promise<any> {
    const options = { headers: { 'Content-Type': 'multipart/form-data' } };
    return req
      .post(`/Employee/upload/${type}`, data, options)
      .then((res) => res.data);
  },
};

export default resume;
