import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createReduxHistoryContext } from 'redux-first-history';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import rootReducer from '../reducers/rootReducer';
import createSagaMiddleweare from 'redux-saga';
import registerMiddleware from '@middlewares/registerMiddleware';
import loginMiddleware from '@middlewares/loginMiddleware';
import logoutMiddleware from '@middlewares/logoutMiddleware';
import setNewPassMiddleware from '@middlewares/setNewPassMiddleware';
import translitRegisterFormMiddleware from '@middlewares/translitRegisterFormMiddleware';
import logger from '@middlewares/devMiddlewares/logger';
import resumeMiddleware from '../middlewares/resumeMiddleware';
import { watcher } from '../../services/store/sagas';

/**
 * @description не помню кто точно мне мешал-глючил - вроде реакт-роутер или хз
 * короче при вызове допустим history.push()
 * нашел эту либу redux-first-history - вроде норм, все работает
 */
const {
  createReduxHistory,
  routerMiddleware,
  routerReducer,
} = createReduxHistoryContext({
  history: createBrowserHistory(),
  oldLocationChangePayload: true,
  reduxTravelling: true,
  // selectRouterState: null,
  // savePreviousLocations: 20,
});

const sagaMiddleweare = createSagaMiddleweare();

const middlewares = [
  thunk,
  sagaMiddleweare,
  routerMiddleware,
  loginMiddleware,
  registerMiddleware,
  logoutMiddleware,
  setNewPassMiddleware,
  translitRegisterFormMiddleware,
  // changeLoginMiddleware, // adel
  // changePassMiddleware, // adel
  resumeMiddleware,
];

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(logger);
}

const store = createStore(
  rootReducer(routerReducer),
  composeWithDevTools(applyMiddleware(...middlewares)),
);
sagaMiddleweare.run(watcher);

const history = createReduxHistory(store);

export { history, store };
