import { makeStyles } from '@material-ui/core';

import BG from '../../images/login-bg/BG-purple.png';

export const useLoginStyles = makeStyles((theme) => ({
  btnBack: {
    position: 'absolute',
    top: 0,
    left: -160,
    [theme.breakpoints.down('sm')]: {
      left: 0,
      top: -60,
    },
  },
  noAcc: {
    marginTop: 25,
  },
  container: {
    position: 'relative',
    width: '100%',
    minHeight: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundImage: `url(${BG})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'repeat-y',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
  },
  textContainer: {
    color: 'black',
    fontWeight: 'bold',
  },
  mainText: {
    whiteSpace: 'break-spaces',
    [theme.breakpoints.down('xs')]: {
      fontSize: 26,
    },
  },
  formBox: {
    display: 'block',
    border: '3px solid black',
    padding: 48,
    backgroundColor: 'white',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      padding: 20,
      margin: 10,
      width: 'auto',
    },
  },
  logo: {
    height: 72,
    marginBottom: 100,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
    },
  },
  loginForm: {
    width: '100%',
  },
  registration: {
    marginTop: 34,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  langBtns: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      marginBottom: 40,
    },
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  wrapper: {
    width: '100%',
    maxWidth: 856,
    margin: 90,
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    [theme.breakpoints.down('md')]: {
      margin: '90px 0',
    },
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    color: 'black',
    fontWeight: 'bold',
    marginTop: 8,
  },
  thankyou: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 30,
    [theme.breakpoints.down('sm')]: {
      padding: 10,
    },
  },
  tyTitle: {
    margin: 15,
  },
  tyButton: {
    width: 300,
    alignSelf: 'center',
    marginTop: 30,
  },
  btnContainer: {
    marginTop: 80,
    display: 'flex',
    justifyContent: 'space-between',
    gap: 10,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  homeBtns: {
    minWidth: 350,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: 'auto',
    },
  },
}));
