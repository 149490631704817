import { actionTypes, change } from 'redux-form';
import { transliteration } from '../../utils/transliteration';

const assignedTranslitFields = {
  lastname: 'translitLastname',
  lastName: 'translitLastname',
  firstName: 'translitFirstName',
  name: 'translitName',
  patronymic: 'translitPatronymic',
};

const mainResumeField = [
  'lastName',
  'firstName',
  'translitFirstName',
  'translitLastname',
  'patronymic',
  'translitPatronymic',
];
/**
 * @description проверка что экшн редаксФормы именно с формы регистрации
 */
const isFormAndFieldsMatchForChange = (action) => {
  const { meta } = action;
  const { field, form } = meta;
  const matchedField = assignedTranslitFields[field];

  if (
    (form === 'register' && matchedField) ||
    (form === 'resume' && matchedField)
  ) {
    return true;
  } else {
    return false;
  }
};

const upFirstLetter = (text) => {
  return text?.charAt(0).toUpperCase() + text?.slice(1);
};
/**
 * @description слушаем печатание в обычные ФИО поля и если на русском,
 * ручками вызываем экшн редаксФормы чтобы изменить поля которые должны быть
 * транслитнутыми
 */
const translitRegisterFormMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  if (
    action.type === actionTypes.CHANGE &&
    isFormAndFieldsMatchForChange(action)
  ) {
    //const locale = store.getState().lang.locale;
    //if (locale === 'RU') {
    const { meta, payload } = action;
    const { field, form } = meta;
    const matchedField = assignedTranslitFields[field];
    const translite = transliteration();

    store.dispatch(
      change(form, matchedField, translite(upFirstLetter(payload)), true),
    );
    // }
  }
  if (action.type === actionTypes.BLUR) {
    const { meta, payload } = action;
    const { field, form } = meta;
    if (mainResumeField.includes(field) && form === 'resume') {
      store.dispatch(change(form, field, upFirstLetter(payload), true));
    }
  }
  return result;
};

export default translitRegisterFormMiddleware;
