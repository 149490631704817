import { store } from '../../store/configureStore';
import { get } from '@utils/utils';
import { enqueueSnackbar } from '../../actions/notifications';

const getNotifyType = (code) => {
  if (code < 200) {
    return 'info';
  } else if (code >= 200 && code < 300) {
    return 'success';
  } else if (code >= 300 && code < 400) {
    return 'warning';
  } else if (code >= 400) {
    return 'error';
  } else {
    return 'default';
  }
};

const showNotify = (code, message) => {
  const variant = getNotifyType(code);
  return store.dispatch(
    enqueueSnackbar({
      message,
      options: {
        autoHideDuration: variant === 'error' ? 8000 : 2000,
        key: Date.now(),
        variant,
      },
    }),
  );
};

// TODO: переделать вывод сообщений - ща некрасиво
const errMsgComp = ({ errMessage = '', message = '', detail = '' }) => {
  const err = errMessage ? `${errMessage}` : '';
  const msg = message ? `${message}` : '';
  const res = detail || `${err} ${msg}`;
  return res;
};
/**
 * @description слушатель ошибок - дергает Алерт
 * @param error объект ошибки аксиоса с бэка
 */

export const handleResponseErrors = (error) => {
  const code = get(error, 'response.status');
  if (code && code !== 401) {
    let messageObj = get(error, 'response.data.exception.response');
    let errMessage = get(messageObj, 'error', messageObj);
    let message = get(messageObj, 'message');
    let detail = get(messageObj, 'detail');
    if (!messageObj) {
      messageObj = get(error, 'response.data.exception');
      errMessage = get(messageObj, 'name');
      message = get(messageObj, 'message');
    }
    showNotify(code, errMsgComp({ errMessage, message, detail }));
  }
};

/**
 * @description слушатель ответа - дергает Алерт
 * @param response объект ответа аксиоса с бэка
 */
export const handleResponseSuccess = (response) => {
  const code = get(response, 'status');
  const config = get(response, 'config');
  if (code && config) {
    const { method, url } = config;
    if (['post', 'put'].includes(method) && url === '/Employee') {
      const message = get(response, 'statusText');
      showNotify(code, message);
    }
  }
};
