import { volunteerConstants } from '@constants/volunteer';
import apiVolunteer from '../api/routes/volunteer';
import { deleteAuthToken } from '@utils/utils';
import { enqueueSnackbar } from './notifications';

export const register = (credentials) => (dispatch) => {
  dispatch({ type: volunteerConstants.REGISTER_FETCHING });
  return apiVolunteer
    .register(credentials)
    .then((data) => {
      dispatch({
        type: volunteerConstants.REGISTER_SUCCESS,
        payload: data,
      });
      dispatch({
        type: volunteerConstants.REGISTER_THANKYOU,
      });
    })
    .catch((error) => {
      let errMessage =
        error?.response?.data?.exception?.message || error.message;
      if (errMessage.includes('duplicate')) {
        if (
          error?.response?.data?.exception?.response?.detail?.includes('email')
        ) {
          errMessage = 'This Email is already registered';
        }
        if (
          error?.response?.data?.exception?.response?.detail?.includes('phone')
        ) {
          errMessage = 'This Phone is already registered';
        }
        dispatch(
          enqueueSnackbar({
            message: errMessage,
            options: {
              autoHideDuration: 5000,
              key: Date.now(), //+ index
              variant: 'error',
            },
          }),
        );
      }
      dispatch({ type: volunteerConstants.REGISTER_FAILURE });
    });
};

export const sayThankYou = () => {
  return {
    type: volunteerConstants.REGISTER_THANKYOU,
  };
};
export const sayThankYouClose = () => {
  return {
    type: volunteerConstants.REGISTER_THANKYOU_CLOSE,
  };
};
export const cancelPassRecovery = () => {
  return {
    type: volunteerConstants.CANCEL_PASS_RECOVERY,
  };
};

export const login = (credentials) => (dispatch) => {
  dispatch({ type: volunteerConstants.LOGIN_FETCHING });
  const newCredentials = { ...credentials, password: credentials.logPassword };
  return apiVolunteer
    .login(newCredentials)
    .then((data) => {
      return dispatch({
        type: volunteerConstants.LOGIN_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({ type: volunteerConstants.LOGIN_FAILURE });
      let errorMessage = error?.message;
      if (error?.response?.status === 401) {
        errorMessage = 'Wrong login or password entered Please try again';
      }

      dispatch(
        enqueueSnackbar({
          message: errorMessage,
          options: {
            autoHideDuration: 4000,
            key: Date.now(), //+ index
            variant: 'error',
          },
        }),
      );
    });
};

export const setInitialLoginType = (loginType) => (dispatch) => {
  return dispatch({
    type: volunteerConstants.SET_LOGIN_TYPE,
    payload: loginType,
  });
};
/**
 * @description: send newEmail or newPhone for getting veirfication
 * code for verify email/phone before change
 * @credentials  old and new email and phone
 */
export const generateCode = (credentials) => (dispatch) => {
  const { loginType, newLoginEmail, newLoginPhone } = credentials;
  const newLogin = {
    type: loginType === 1 ? 1 : 2,
    newVerifed: loginType === 1 ? newLoginEmail : newLoginPhone,
  };
  dispatch({ type: volunteerConstants.GENERATE_CODE_FETCHING });
  return apiVolunteer
    .generateCode(newLogin)
    .then((data) => {
      return dispatch({
        type: volunteerConstants.GENERATE_CODE_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch(
        enqueueSnackbar({
          message: error.message,
          options: {
            autoHideDuration: 5000,
            key: Date.now(), //+ index
            variant: 'error',
          },
        }),
      );
      dispatch({ type: volunteerConstants.GENERATE_CODE_FAILURE });
    });
};
/**
 * @description: send verification code for verify email/phone before change
 * @credentials  old and new email and phone and then change login if success
 */
export const verifyCode = (data) => (dispatch) => {
  const { code, loginTypeField, newLoginEmail, newLoginPhone } = data;
  const formData =
    loginTypeField === 1
      ? {
          email: newLoginEmail,
          code,
        }
      : {
          phone: newLoginPhone,
          code,
        };
  dispatch({ type: volunteerConstants.VERIFY_CODE_FETCHING });

  const verifyLoginRequest =
    loginTypeField === 1 ? apiVolunteer.verifyEmail : apiVolunteer.verifyPhone;
  return verifyLoginRequest(formData)
    .then((data) => {
      return dispatch({
        type: volunteerConstants.VERIFY_CODE_SUCCESS,
        payload: data,
      });
    })
    .then((data) => {
      // last request to email/change or phone/change
      const changeLogin =
        loginTypeField === 1
          ? apiVolunteer.changeEmail
          : apiVolunteer.changePhone;
      return changeLogin(data)
        .then(
          console.log(
            'the code is not verified. You need to log in again',
            data,
          ),
        )
        .catch((error) => {
          dispatch(
            enqueueSnackbar({
              message: error.message,
              options: {
                autoHideDuration: 5000,
                key: Date.now(), //+ index
                variant: 'error',
              },
            }),
          );
          // dispatch({ type: volunteerConstants.VERIFY_CODE_FAILURE });
        });
    })
    .catch((error) => {
      dispatch(
        enqueueSnackbar({
          message: error.message,
          options: {
            autoHideDuration: 5000,
            key: Date.now(), //+ index
            variant: 'error',
          },
        }),
      );
      dispatch({ type: volunteerConstants.VERIFY_CODE_FAILURE });
    });
};

/**
 * @description:  cancel change login then close verify code modal
 * @credentials  no params
 */

export const cancelChangeLogin = () => (dispatch) => {
  dispatch(
    enqueueSnackbar({
      message: 'You canceled login changing Please authorize again',
      options: {
        autoHideDuration: 5000,
        key: Date.now(), //+ index
        variant: 'warning',
      },
    }),
  );
  dispatch({
    type: volunteerConstants.CANCEL_LOGIN_CHANGING,
  });
};

/**
 * @description:  change email or phone
 * @credentials  old and new email or phones and verification code
 */
export const changeLogin = (data) => (dispatch) => {
  const {
    code,
    loginTypeField,
    newLoginEmail,
    newLoginPhone,
    oldLoginPhone,
    oldLoginEmail,
  } = data;
  const formData =
    loginTypeField === 2
      ? {
          phone: newLoginPhone,
          oldPhone: oldLoginPhone,
          code,
        }
      : {
          email: newLoginEmail,
          oldEmail: oldLoginEmail,
          code,
        };
  dispatch({ type: volunteerConstants.CHANGE_LOGIN_FETCHING });

  const changeLoginRequest =
    loginTypeField === 1 ? apiVolunteer.changeEmail : apiVolunteer.changePhone;
  return changeLoginRequest(formData)
    .then((data) => {
      dispatch({
        type: volunteerConstants.CHANGE_LOGIN_SUCCESS,
        payload: data,
      });
      dispatch({
        type: volunteerConstants.CHANGE_LOGIN_MODAL_OPEN,
      });
    })
    .catch((error) => {
      let errMessage =
        error?.response?.data?.exception?.message || error.message;
      if (errMessage.includes('duplicate')) {
        if (error?.response?.data?.exception?.detail?.includes('email')) {
          errMessage = 'This Email is already registered';
        }
        if (error?.response?.data?.exception?.detail?.includes('phone')) {
          errMessage = 'This Phone is already registered';
        }
        dispatch(
          enqueueSnackbar({
            message: errMessage,
            options: {
              autoHideDuration: 5000,
              key: Date.now(), //+ index
              variant: 'error',
            },
          }),
        );
      }

      dispatch({ type: volunteerConstants.CHANGE_LOGIN_FAILURE });
    });
};

/**
 * @description:  update email or phone in application
 * @credentials  new email or phone
 */
export const updateContacts = (data) => (dispatch) => {
  dispatch({ type: volunteerConstants.UPDATE_CONTACTS_FETCHING });

  return apiVolunteer
    .updateContacts(data)
    .then((data) => {
      dispatch({
        type: volunteerConstants.UPDATE_CONTACTS_SUCCESS,
        payload: data,
      });
      dispatch(
        enqueueSnackbar({
          message: 'Your contacts are updated',
          options: {
            autoHideDuration: 5000,
            key: Date.now(), //+ index
            variant: 'success',
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(
        enqueueSnackbar({
          message: error?.response?.data?.exception?.message || error.message,
          options: {
            autoHideDuration: 5000,
            key: Date.now(), //+ index
            variant: 'error',
          },
        }),
      );
      dispatch({ type: volunteerConstants.UPDATE_CONTACTS_FAILURE });
    });
};

export const submitFeedback = (feedbackData) => (dispatch) => {
  dispatch({ type: volunteerConstants.SUBMIT_FEEDBACK_FETCHING });

  return apiVolunteer
    .submitFeedback(feedbackData)
    .then((response) => {
      dispatch({
        type: volunteerConstants.SUBMIT_FEEDBACK_SUCCESS,
        payload: response,
      });

      dispatch(
        enqueueSnackbar({
          message: 'Ваше сообщение было успешно отправлено!',
          options: {
            autoHideDuration: 5000,
            key: Date.now(),
            variant: 'success',
          },
        })
      );
      dispatch(closeFeedbackModal());
    })
    .catch((error) => {
      dispatch({
        type: volunteerConstants.SUBMIT_FEEDBACK_FAILURE,
      });

      dispatch(
        enqueueSnackbar({
          message: error?.response?.data?.exception?.message || error.message,
          options: {
            autoHideDuration: 5000,
            key: Date.now(),
            variant: 'error',
          },
        })
      );
    });
};

export const closeChangeLoginModal = () => {
  return { type: volunteerConstants.CHANGE_LOGIN_MODAL_CLOSE };
};

export const closeRefuseModal = () => {
  return { type: volunteerConstants.REFUSE_MODAL_CLOSE };
};
export const openRefuseModal = () => {
  return { type: volunteerConstants.REFUSE_MODAL_OPEN };
};

export const closeFeedbackModal = () => {
  return { type: volunteerConstants.FEEDBACK_MODAL_CLOSE };
};
export const openFeedbackModal = () => {
  return { type: volunteerConstants.FEEDBACK_MODAL_OPEN };
};
/**
 * @description этот метод работтает сразу после редиректа с социалок
 * редирект токен для миддлвари loginMiddleware
 * @param {*} type - тип авторизации - ВК/ФБ/Гугл
 * @param {*} params параметры которые прилетают также в урле из редиректа
 */
export const loginBySocial = (type, params) => (dispatch) => {
  dispatch({ type: volunteerConstants.LOGIN_SOCIAL_FETCHING });
  return apiVolunteer
    .loginBySocial(type, params)
    .then((data) => {
      return dispatch({
        type: volunteerConstants.LOGIN_SOCIAL_SUCCESS,
        payload: data,
      });
    })
    .catch(() => dispatch({ type: volunteerConstants.LOGIN_SOCIAL_FAILURE }));
};

/**
 * @description удаляем куку и слушаем мидлварью этот экшн
 * чтобы выйти на страницу логина
 */
export const logout = () => (dispatch) => {
  dispatch({ type: volunteerConstants.LOGOUT_FETCHING });
  try {
    deleteAuthToken();
    return dispatch({
      type: volunteerConstants.LOGOUT_SUCCESS,
    });
  } catch (error) {
    return dispatch({ type: volunteerConstants.LOGOUT_FAILURE });
  }
};
/**
 * @description для письма на почту
 * @param {*} data - email
 */
export const resetPass = (data) => (dispatch) => {
  dispatch({ type: volunteerConstants.RESET_PASS_FETCHING });
  return apiVolunteer
    .resetPass(data)
    .then((tokenData) => {
      if (tokenData && tokenData.token) {
        deleteAuthToken();
        return dispatch({
          type: volunteerConstants.RESET_PASS_SUCCESS,
          payload: tokenData.token,
        });
      } else {
        dispatch(
          enqueueSnackbar({
            message: 'This email is not registered',
            options: {
              autoHideDuration: 5000,
              key: Date.now(), //+ index
              variant: 'error',
            },
          }),
        );
        dispatch({ type: volunteerConstants.RESET_PASS_FAILURE });
      }
    })
    .catch((error) => {
      dispatch(
        enqueueSnackbar({
          message: error?.response?.data?.exception?.message || error.message,
          options: {
            autoHideDuration: 5000,
            key: Date.now(), //+ index
            variant: 'error',
          },
        }),
      );
      dispatch({ type: volunteerConstants.RESET_PASS_FAILURE });
    });
};

/**
 * @description новый пароль вместе с токеном восстановления
 */
export const setNewPass = (data) => (dispatch, getState) => {
  dispatch({ type: volunteerConstants.SET_NEW_PASS_FETCHING });
  return apiVolunteer
    .setNewPass({
      ...data,
      token: getState().volunteer.resetPassToken,
    })
    .then((isPasswordUpdated) => {
      if (isPasswordUpdated) {
        //return
        dispatch({
          type: volunteerConstants.SET_NEW_PASS_SUCCESS,
        });
        dispatch({
          type: volunteerConstants.CHANGE_PASS_MODAL_OPEN,
        });
      } else {
        return dispatch({ type: volunteerConstants.SET_NEW_PASS_FAILURE });
      }
    })
    .catch(() => dispatch({ type: volunteerConstants.SET_NEW_PASS_FAILURE }));
};

/**
 * @description request for changing password with oldPassword newPassword and email
 */
export const changePass = (data) => (dispatch) => {
  const newPassData = { newPassword: data?.password };
  dispatch({ type: volunteerConstants.CHANGE_PASS_FETCHING });
  return apiVolunteer
    .changePass(newPassData)
    .then((data) => {
      dispatch({
        type: volunteerConstants.CHANGE_PASS_SUCCESS,
        payload: data,
      });
      dispatch({
        type: volunteerConstants.CHANGE_PASS_MODAL_OPEN,
      });
    })
    .catch((error) => {
      dispatch(
        enqueueSnackbar({
          message: error?.response?.data?.exception?.message || error.message,
          options: {
            autoHideDuration: 5000,
            key: Date.now(), //+ index
            variant: 'error',
          },
        }),
      );
      dispatch({ type: volunteerConstants.CHANGE_PASS_FAILURE });
    });
};
/**
 * @description refuse participate
 */
export const refuseParticipate = (params, body) => (dispatch) => {
  //console.log('action.refuseParticipate.data', data);
  dispatch({ type: volunteerConstants.REFUSE_PARTICIPATE_FETCHING });
  return apiVolunteer
    .refuseParticipate(params, body)
    .then((data) => {
      dispatch({
        type: volunteerConstants.REFUSE_PARTICIPATE_SUCCESS,
        payload: data,
      });
      dispatch(
        enqueueSnackbar({
          message: 'Your application for participation has been withdrawn',
          options: {
            autoHideDuration: 5000,
            key: Date.now(), //+ index
            variant: 'success',
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(
        enqueueSnackbar({
          message: error?.response?.data?.exception?.message || error.message,
          options: {
            autoHideDuration: 5000,
            key: Date.now(), //+ index
            variant: 'error',
          },
        }),
      );
      dispatch({ type: volunteerConstants.REFUSE_PARTICIPATE_FAILURE });
    });
};

export const closeChangePassModal = () => {
  return { type: volunteerConstants.CHANGE_PASS_MODAL_CLOSE };
};
/**
 * @description дергается сразу после перехода
 * по ссылке для восстановления
 */
export const checkNewPassToken = (data) => (dispatch) => {
  dispatch({ type: volunteerConstants.CHECK_NEW_PASS_TOKEN_FETCHING });
  return apiVolunteer
    .checkPassToken(data)
    .then((isTokenValid) => {
      if (isTokenValid) {
        return dispatch({
          type: volunteerConstants.CHECK_NEW_PASS_TOKEN_SUCCESS,
          payload: data,
        });
      } else {
        return dispatch({
          type: volunteerConstants.CHECK_NEW_PASS_TOKEN_FAILURE,
        });
      }
    })
    .catch(() =>
      dispatch({ type: volunteerConstants.CHECK_NEW_PASS_TOKEN_FAILURE }),
    );
};

/**
 * @description дергается при старте приложения
 */
export const getProfile = () => async (dispatch) => {
  dispatch({ type: volunteerConstants.GET_PROFILE_FETCHING });
  try {
    const data = await apiVolunteer.getProfile();
    if (!data) {
      throw new Error('NO_USER');
    }
    return dispatch({
      type: volunteerConstants.GET_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    return dispatch({ type: volunteerConstants.GET_PROFILE_FAILURE });
  }
};
