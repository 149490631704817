import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider as IntlProviderHOC } from 'react-intl';
// TODO: переписать на TS
const IntlProvider = (props) => {
  const { defaultLocale, messages, locale, updated } = useSelector(
    (state) => state.lang,
  );
  //console.log('IntlProvider', messages);
  return (
    <IntlProviderHOC
      {...props}
      locale={locale}
      defaultLocale={defaultLocale}
      key={locale + updated}
      messages={messages[locale]}
      textComponent={Fragment}
    />
  );
};

export default IntlProvider;
