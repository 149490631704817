export const SELECT_TEST = 'SELECT_TEST';
export const REMOVE_TEST = 'REMOVE_TEST';
export const SEND_TEST = 'SEND_TEST';
export const SEND_TEST_FAILED = 'SEND_TEST_FAILED';
export const SET_TESTS_LIST = 'SET_TESTS_LIST';
export const SET_SINGLE_TEST = 'SET_SINGLE_TEST';
export const SET_TESTS_INFO = 'SET_TESTS_INFO';
export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';
export const CLEAN_RESULT = 'CLEAN_RESULT';
