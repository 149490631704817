import { createLogger } from 'redux-logger';
import { languageConstants } from '@constants/lang';
import { volunteerConstants } from '@constants/volunteer';
import { utilsConstants } from '@constants/utils';
import { resumeConstants } from '@constants/resume';

/**
 * @description сюда можно добавлять то что будет скрываться в консоли
 * а можно наоборот убирать если хочется понаблюдать не через redux-devtools
 */
const excludedDiffActions = [
  '@@redux-form',
  '_NOTIFICATION',
  '@@router/',
  'LOGOUT_',
  '@@init',
  'GET_LIST_',
  //'GET_PROFILE_',
  //'GET_NEWS_',
  'GET_ALERTS_',
  'GET_UTILS',
  'SNACKBAR',
  '@@redux-form/REGISTE',
  'LOGIN_SOCIAL',
  //'RESUME_GET',
  '@@redux-form/UNREGISTER_FIELD',
  '@@redux-form/INITIALIZE',
  '@@redux-form/FOCUS',
  '@@redux-form/BLUR',
  '@@redux-form/CHANGE',
  '@@redux-form/TOUCH',
  '@@redux-form/UPDATE_SYNC_ERRORS',
  '@@redux-form/DESTROY',
  'GET_TRANSLATIONS_',
];
/**
 * @description раскрашвает заголовок экшна
 * @param type константа экшна
 */
const getActionTypeColor = (type) => {
  switch (type) {
    case languageConstants[type]:
      return '#A9A9A9';
    case volunteerConstants[type]:
      return '#FFD700';
    case utilsConstants[type]:
      return '#9370DB';
    case resumeConstants[type]:
      return '#00BFFF';
    default:
      return 'red';
  }
};
/**
 * @description раскраска для мидлвари redux-logger
 */
const logger = createLogger({
  timestamp: false,
  collapsed: true,
  diff: true,
  colors: {
    title: ({ type }) => getActionTypeColor(type),
    prevState: () => '#242424',
    action: () => '#04ff00',
    nextState: () => '#242424',
    error: () => 'red',
  },
  /**
   * @description фильтр сообщений. если вас достал мусор в консоли
   * @param type полное название экшна
   */
  predicate: (_, { type }) =>
    !excludedDiffActions.some((prefix) => type.includes(prefix)),
});

export default logger;
