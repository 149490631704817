export const volunteerConstants = {
  REGISTER_FETCHING: 'REGISTER_FETCHING',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',
  REGISTER_THANKYOU: 'REGISTER_THANKYOU',
  REGISTER_THANKYOU_CLOSE: 'REGISTER_THANKYOU_CLOSE',

  LOGOUT_FETCHING: 'LOGOUT_FETCHING',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',

  LOGIN_FETCHING: 'LOGIN_FETCHING',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  LOGIN_SOCIAL_FETCHING: 'LOGIN_SOCIAL_FETCHING',
  LOGIN_SOCIAL_SUCCESS: 'LOGIN_SOCIAL_SUCCESS',
  LOGIN_SOCIAL_FAILURE: 'LOGIN_SOCIAL_FAILURE',

  RESET_PASS_FETCHING: 'RESET_PASS_FETCHING',
  RESET_PASS_SUCCESS: 'RESET_PASS_SUCCESS',
  RESET_PASS_FAILURE: 'RESET_PASS_FAILURE',

  SET_NEW_PASS_FETCHING: 'SET_NEW_PASS_FETCHING',
  SET_NEW_PASS_SUCCESS: 'SET_NEW_PASS_SUCCESS',
  SET_NEW_PASS_FAILURE: 'SET_NEW_PASS_FAILURE',

  CHECK_NEW_PASS_TOKEN_FETCHING: 'CHECK_NEW_PASS_TOKEN_FETCHING',
  CHECK_NEW_PASS_TOKEN_SUCCESS: 'CHECK_NEW_PASS_TOKEN_SUCCESS',
  CHECK_NEW_PASS_TOKEN_FAILURE: 'CHECK_NEW_PASS_TOKEN_FAILURE',

  GET_PROFILE_FETCHING: 'GET_PROFILE_FETCHING',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

  CHANGE_LOGIN_FETCHING: 'CHANGE_LOGIN_FETCHING',
  CHANGE_LOGIN_SUCCESS: 'CHANGE_LOGIN_SUCCESS',
  CHANGE_LOGIN_FAILURE: 'CHANGE_LOGIN_FAILURE',

  CANCEL_LOGIN_CHANGING: 'CANCEL_LOGIN_CHANGING',
  CANCEL_PASS_RECOVERY: 'CANCEL_PASS_RECOVERY',

  CHANGE_PASS_FETCHING: 'CHANGE_PASS_FETCHING',
  CHANGE_PASS_SUCCESS: 'CHANGE_PASS_SUCCESS',
  CHANGE_PASS_FAILURE: 'CHANGE_PASS_FAILURE',

  GENERATE_CODE_FETCHING: 'GENERATE_CODE_FETCHING',
  GENERATE_CODE_SUCCESS: 'GENERATE_CODE_SUCCESS',
  GENERATE_CODE_FAILURE: 'GENERATE_CODE_FAILURE',

  UPDATE_CONTACTS_FETCHING: 'UPDATE_CONTACTS_FETCHING',
  UPDATE_CONTACTS_SUCCESS: 'UPDATE_CONTACTS_SUCCESS',
  UPDATE_CONTACTS_FAILURE: 'UPDATE_CONTACTS_FAILURE',

  VERIFY_CODE_FETCHING: 'VERIFY_CODE_FETCHING',
  VERIFY_CODE_SUCCESS: 'VERIFY_CODE_SUCCESS',
  VERIFY_CODE_FAILURE: 'VERIFY_CODE_FAILURE',

  GET_NEWS_FETCHING: 'GET_NEWS_FETCHING',
  GET_NEWS_SUCCESS: 'GET_NEWS_SUCCESS',
  GET_NEWS_FAILURE: 'GET_NEWS_FAILURE',

  READ_NEWS_FETCHING: 'READ_NEWS_FETCHING',
  READ_NEWS_SUCCESS: 'READ_NEWS_SUCCESS',
  READ_NEWS_FAILURE: 'READ_NEWS_FAILURE',
  READ_NEWS_LOCAL: 'READ_NEWS_FETCHING',

  CHANGE_LOGIN_MODAL_OPEN: 'CHANGE_LOGIN_MODAL_OPEN',
  CHANGE_LOGIN_MODAL_CLOSE: 'CHANGE_LOGIN_MODAL_CLOSE',

  CHANGE_PASS_MODAL_OPEN: 'CHANGE_PASS_MODAL_OPEN',
  CHANGE_PASS_MODAL_CLOSE: 'CHANGE_PASS_MODAL_CLOSE',

  REFUSE_PARTICIPATE_FETCHING: 'REFUSE_PARTICIPATE_FETCHING',
  REFUSE_PARTICIPATE_SUCCESS: 'REFUSE_PARTICIPATE_SUCCESS',
  REFUSE_PARTICIPATE_FAILURE: 'REFUSE_PARTICIPATE_FAILURE',

  REFUSE_MODAL_OPEN: 'REFUSE_MODAL_OPEN',
  REFUSE_MODAL_CLOSE: 'REFUSE_MODAL_CLOSE',

  FEEDBACK_MODAL_OPEN: 'FEEDBACK_MODAL_OPEN',
  FEEDBACK_MODAL_CLOSE: 'FEEDBACK_MODAL_CLOSE',

  SUBMIT_FEEDBACK_FETCHING: 'SUBMIT_FEEDBACK_FETCHING',
  SUBMIT_FEEDBACK_SUCCESS: 'SUBMIT_FEEDBACK_SUCCESS',
  SUBMIT_FEEDBACK_FAILURE: 'SUBMIT_FEEDBACK_FAILURE',

  SET_LOGIN_TYPE: 'SET_LOGIN_TYPE',
};
