import React from 'react'
import ReactGoogleSlides from "react-google-slides";
import {useSelector} from 'react-redux';
import {IRootState} from '../pages/Cabinet';

const SlideShow = ({url}: any) => {
  const {height, width} = useSelector((state: IRootState) => state.cabinet.screenSize)
  return (
    <ReactGoogleSlides
      width={width}
      height={height}
      slidesLink={url}
      slideDuration={5}
      showControls
      loop
    />
  );
}

export default SlideShow
