import { Calendar, EventPropGetter, momentLocalizer } from 'react-big-calendar';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import 'moment/locale/ru';
import 'moment/locale/en-gb';

import MyToolbar from './MyToolbar';

import './react-big-calendar.css';
import ThreeDaysWeek from './ThreeDaysWeek';
import { IRootState } from '../../pages/Cabinet';
import { setCalendarTabValue } from '../../../js/actions/cabinet';
import { makeStyles, Typography } from '@material-ui/core';
import { getWorkShifts } from '../../../js/actions/schedule';
import { useIntl } from 'react-intl';
import Modal from '../../../rootComponents/Modal/Modal';
import WorkEvent from './WorkEvent';
import InterviewSchedule from './InterviewSchedule';
import { getFormattedDate } from './utils/dates';

const useStyles = makeStyles((theme) => ({
  event: {
    backgroundColor: theme.palette.secondary.light,
    fontSize: 13,
  },
  selectedEvent: {
    backgroundColor: `${theme.palette.secondary.main}!important`,
    fontSize: 13,
  },
  dialogContent: {
    padding: 20,
  },
  title: {
    marginBottom: 20,
  },
  btn: {
    marginTop: 20,
  },
  infoBox: {
    marginTop: 20,
  },
  calendarContainer: {
    width: '100%',
  },
}));

const workComponents = {
  event: WorkEvent,
  toolbar: MyToolbar,
};

interface Props {
  tab: string;
}

const Scheduler: FC<Props> = ({ tab }) => {
  const { formatMessage: fm } = useIntl();
  const { locale } = useSelector((state: IRootState) => state.lang);
  const {
    steps: { userId: id },
  } = useSelector((state: IRootState) => state.resume);
  const { workShifts } = useSelector((state: IRootState) => state.schedule);

  const { width } = useSelector(
    (state: IRootState) => state.cabinet.screenSize,
  );

  const [start, setStart] = useState<Date | undefined>(
    moment().day(1).hour(6).toDate(),
  );
  const [end, setEnd] = useState<Date | undefined>(
    moment().day(7).hour(22).toDate(),
  );
  const [open, setOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState<any>({});

  const dispatch = useDispatch();
  const classes = useStyles();

  const loc = locale === 'EN' ? 'en-gb' : locale;
  moment.locale(loc);
  const localizer = momentLocalizer(moment);

  useEffect(() => {
    if (start && end && id) {
      dispatch(
        getWorkShifts({
          applicationId: id,
          startDate: start,
          endDate: end,
        }),
      );
    }
  }, [start, end, id]);

  const handleChangeDate = useCallback((range: any) => {
    if (range.length === 1) {
      setStart(moment(range[0]).hour(6).toDate());
      setEnd(moment(range[0]).hour(22).toDate());
    } else {
      setStart(moment(range[0]).hour(6).toDate());
      setEnd(moment(range[6]).hour(22).toDate());
    }
  }, []);

  const shiftEventsList = useMemo(() => {
    return workShifts.map((el) => {
      return {
        id: el.id,
        start: getFormattedDate(el.shiftScheduleTask.startDate),
        end: getFormattedDate(el.shiftScheduleTask.endDate),
        title: el.zone.i18n[locale.toLowerCase()] || el.zone.value,
        details: el.shiftScheduleTask.details,
        variant: 'shift',
      };
    });
  }, [workShifts]);

  const handleChangeView = (params: string) => {
    dispatch(setCalendarTabValue(params === 'day' ? 1 : 0));
  };

  const workDialogContent = useMemo(() => {
    return (
      <div className={classes.dialogContent}>
        <Typography variant='h5' align='center' className={classes.title}>
          {[
            fm({ id: 'calendar.dialog.work.title' }),
            new Date(selectedSlot.start).toLocaleString(locale, {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            }),
          ].join(' ')}
        </Typography>
        <Typography>
          {`${fm({ id: 'calendar.dialog.work.start.text' })}
          ${moment(selectedSlot.start).format('HH:mm')}
          ${fm({ id: 'calendar.dialog.work.end.text' })}
          ${moment(selectedSlot.end).format('HH:mm')} `}
        </Typography>
        <div className={classes.infoBox}>
          <Typography>
            {[fm({ id: 'calendar.dialog.work.zone' }), selectedSlot.title].join(
              ' ',
            )}
          </Typography>
          <Typography>
            {[
              fm({ id: 'calendar.dialog.work.details' }),
              selectedSlot.details,
            ].join(' ')}
          </Typography>
        </div>
      </div>
    );
  }, [selectedSlot, classes, locale]);

  const eventPropGetter: EventPropGetter<any> = (
    event,
    calendarStart,
    calendarEnd,
    isSelected,
  ) => {
    return {
      className: !isSelected ? classes.event : classes.selectedEvent,
    };
  };

  const handleSelectSlot = useCallback((value) => {
    setOpen(true);
    setSelectedSlot(value);
  }, []);

  return (
    <div className={classes.calendarContainer}>
      {tab !== 'interview' ? (
        <Calendar
          events={shiftEventsList}
          localizer={localizer}
          views={{
            week: width > 740 || (ThreeDaysWeek as any),
            day: true,
          }}
          startAccessor='start'
          endAccessor='end'
          components={workComponents}
          toolbar={true}
          defaultView='week'
          onView={handleChangeView}
          eventPropGetter={eventPropGetter}
          defaultDate={start}
          onRangeChange={handleChangeDate}
          timeslots={2}
          min={new Date(0, 0, 0, 8, 0, 0)}
          max={new Date(0, 0, 0, 22, 0, 0)}
          onSelectEvent={handleSelectSlot}
        />
      ) : (
        <InterviewSchedule />
      )}
      <Modal open={open} onClose={() => setOpen(false)}>
        {workDialogContent}
      </Modal>
    </div>
  );
};

export default memo(Scheduler);
