import { utilsConstants } from '../constants/utils';
import { UtilsActions } from '../actions/utils';

interface IAddressValues {
  value: string | number;
  label: string;
}
interface IUtilsState {
  countries?: IAddressValues[];
  regions?: IAddressValues[];
  cities?: IAddressValues[];
  factCountries?: IAddressValues[];
  factRegions?: IAddressValues[];
  factCities?: IAddressValues[];
  loading: boolean;
  error: string;
}

const initialState: IUtilsState = {
  countries: [],
  regions: [],
  cities: [],
  factCountries: [],
  factRegions: [],
  factCities: [],
  loading: false,
  error: '',
};

export default (state = initialState, action: UtilsActions): IUtilsState => {
  const { payload } = action;
  switch (action.type) {
    case utilsConstants.GET_UTILS_FETCHING:
      return {
        ...state,
        loading: true,
      };
    case utilsConstants.GET_UTILS_SUCCESS:
      return {
        ...state,
        loading: false,
        [payload.type]: payload.data,
        error: '',
      };
    case utilsConstants.GET_UTILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.type,
      };
    default:
      return state;
  }
};
