import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  paper: {
    background: 'none',
    boxShadow: 'none',
    margin: 10,
  },
}));

const useTitleStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const useBodyStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 30,
    backgroundColor: 'white',
    overflowY: 'hidden',
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<Props> = ({ open, onClose, children }) => {
  const classes = useStyles();
  const titleStyle = useTitleStyle();
  const bodyStyle = useBodyStyle();
  return (
    <>
      <Dialog open={open} onClose={onClose} classes={classes}>
        <DialogTitle classes={titleStyle}>
          <Fab onClick={onClose}>
            <CloseIcon />
          </Fab>
        </DialogTitle>
        <DialogContent classes={bodyStyle}>{children}</DialogContent>
      </Dialog>
    </>
  );
};

export default React.memo(Modal);
