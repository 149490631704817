import React, { FC } from 'react';
import cn from 'classnames';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    border: 0,
    boxShadow: 'none',
    outline: 'none !important',
  },
}));

const Btn: FC<ButtonProps> = ({ className, ...props }) => {
  const classes = useStyles();
  return <Button {...props} className={cn(classes.root, className)} />;
};

export default Btn;
