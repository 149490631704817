import Cookies from 'js-cookie';
import { languageConstants } from '@constants/lang';
import { defaultLocale, defaultLanguages, messages } from '@config/lang';
// TODO: переписать на TS
const isLangInList = (lang) => {
  for (let i = 0; i < defaultLanguages.length; i++) {
    if (defaultLanguages[i].key === lang) return lang;
  }
  return null;
};

const getLang = () => {
  const lang = Cookies.get('LANG');
  return isLangInList(lang) || defaultLocale;
};

const lang = getLang();

// interface ILangState {
//   updated: number,
//   loading: boolean,
//   RU: boolean | {[key: string]: string},
//   EN: boolean | {[key: string]: string},
//   locale: string,
//   defaultLocale: string,
//   messages: typeof messages,
//   languages: typeof defaultLanguages,
//   error: string,
// }

const initialState = {
  updated: Date.now(),
  loading: false,
  RU: false,
  EN: false,
  locale: lang,
  defaultLocale: lang,
  messages,
  languages: defaultLanguages,
  error: '',
};

export default (state = initialState, action = {}) => {
  const { payload } = action;
  switch (action.type) {
    case languageConstants.SET_LOCALE:
      return {
        ...state,
        locale: payload,
      };
    case languageConstants.GET_TRANSLATIONS_FETCHING:
      return {
        ...state,
        loading: true,
      };
    case languageConstants.GET_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        updated: Date.now(),
        loading: false,
        [payload.locale]: true,
        messages: {
          ...state.messages,
          [payload.locale]: {
            ...state.messages[payload.locale],
            ...payload.data,
          },
        },
        error: '',
      };
    case languageConstants.GET_TRANSLATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.type,
      };
    default:
      return state;
  }
};
