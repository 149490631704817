import { Dispatch } from 'react';
import apiUserSchedule from '../api/routes/apiUserScedule';
import { cabinetConstants } from '../constants/cabinet';
import * as scheduleConstans from '../constants/scheduleConstants';
import { InterviewStatus } from '../reducers/schedule';
import { enqueueSnackbar } from './notifications';

export const getInterviewSlots = (query: any) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: scheduleConstans.TOGGLE_INTERVIEW_LOADING,
    payload: true,
  });
  apiUserSchedule
    .getInterviewerSlots(query)
    .then((res) => {
      dispatch({
        type: scheduleConstans.GET_INTERVIEW_SLOTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response?.status === 401) {
        dispatch({ type: cabinetConstants.GO_TO_LOGIN });
      }
      dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            autoHideDuration: 5000,
            key: Date.now(), //+ index
            variant: 'error',
          },
        }),
      );
    })
    .finally(() => {
      dispatch({
        type: scheduleConstans.TOGGLE_INTERVIEW_LOADING,
        payload: false,
      });
    });
};

export const getWorkShifts = (query: any) => (dispatch: Dispatch<any>) => {
  apiUserSchedule
    .getWorkShifts(query)
    .then((res) => {
      dispatch({
        type: scheduleConstans.GET_WORK_SHIFTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response?.status === 401) {
        dispatch({ type: cabinetConstants.GO_TO_LOGIN });
      }
      dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            autoHideDuration: 5000,
            key: Date.now(), //+ index
            variant: 'error',
          },
        }),
      );
    });
};

export const selectInerviewSlot = (startTime: Date | string) => async (
  dispatch: Dispatch<any>,
) => {
  try {
    const { data } = await apiUserSchedule.sendSelectedSlot(startTime);
    dispatch({ type: scheduleConstans.SET_INTERVIEW_DATE_INFO, payload: data });
  } catch (err: any) {
    dispatch(removeInterviewDateInfo());
    if (err.response?.status === 401) {
      dispatch({ type: cabinetConstants.GO_TO_LOGIN });
    } else if (err.response?.status === 405) {
      dispatch(changeInterviewStatus('limit'));
    }
    dispatch(
      enqueueSnackbar({
        message: err.message,
        options: {
          autoHideDuration: 5000,
          key: Date.now(), //+ index
          variant: 'error',
        },
      }),
    );
  }
};

export const deleteInerviewSlot = (id: number) => async (
  dispatch: Dispatch<any>,
) => {
  try {
    const { data } = await apiUserSchedule.deleteSelectedSlot(id);
    dispatch(
      enqueueSnackbar({
        message: 'success send time slot',
        options: {
          autoHideDuration: 5000,
          key: Date.now(), //+ index
          variant: 'success',
        },
      }),
    );
  } catch (err: any) {
    if (err.response?.status === 401) {
      dispatch({ type: cabinetConstants.GO_TO_LOGIN });
    }
    dispatch(
      enqueueSnackbar({
        message: err.message,
        options: {
          autoHideDuration: 5000,
          key: Date.now(), //+ index
          variant: 'error',
        },
      }),
    );
  }
};

export const changeInterviewStatus = (status: InterviewStatus) => (
  dispatch: Dispatch<any>,
) => {
  dispatch({ type: scheduleConstans.SET_INTERVIEW_STATUS, payload: status });
};

export const removeInterviewDateInfo = () => (dispatch: Dispatch<any>) => {
  dispatch({ type: scheduleConstans.SET_INTERVIEW_STATUS });
};
