import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC, memo } from 'react';

const useStyles = makeStyles(() => ({
  content: {},
}));

const WorkEvent: FC<any> = (props) => {
  const classes = useStyles();
  return (
    <div>
      {props.title}
      {props.event?.variant === 'shift' && (
        <>
          <Typography>{props.event?.zone}</Typography>
          <Typography>{props.event?.details}</Typography>
        </>
      )}
    </div>
  );
};

export default memo(WorkEvent);
