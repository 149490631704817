export const numberFields = [
  'height',
  'shoulderWidth',
  'sleeveLength',
  'schrittlange',
  // 'footSize',
  'waistGirth',
  'chest',
  'hip',
  'headСircumference',
  // 'passport',
  // 'ITN',
  // 'INILA',
  'volunteerBookNumber',
];

export const objInArrayNumberFields = [['education', ['endYear']]];

export const stringFields = [
  'lastName',
  'firstName',
  'patronymic',
  'translitLastname',
  'translitFirstName',
  'translitPatronymic',
  'email',
  'phoneNumber',
  'vkLink',
  'telegram',
  'specialWorkingConditionsDescription',
];

export const objInArrayStringFields = [
  ['education', ['name', 'specialization']],
  ['experience', ['name', 'companyName']],
  ['volunteerExperience', ['eventName', 'role']],
  ['teamleadExperience', ['eventName', 'role']],
];

export const objInArrayNotEmpty = [
  // name // required-fields // optional-fields
  // ['languages', ['level'], ['lang', 'order']],
  ['education', ['name', 'specialization', 'level'], ['endYear']],
  ['experience', ['name', 'companyName'], ['fromDate', 'toDate']],
  ['volunteerExperience', ['eventName', 'role'], ['date']],
  ['teamleadExperience', ['eventName', 'role'], ['date']],
];

export const fileObjectFields = [
  'passportFile',
  'passportFileSecond',
  'ITNFile',
  'INILAFile',
  'volunteerBookFile',
  'resumeFile',
];
