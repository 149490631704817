import { get } from '@utils/utils';
import clone from 'clone';
// import { diff } from 'deep-object-diff';
import { getElId, extractFilesUrls } from './formatters';
import { fileObjectFields } from './resumeFormattedFields';

const PREFIX = process.env.REACT_APP_BACKEND_API_PREFIX;
const STATIC = '/static';

// TODO: ЕСЛИ ВСЕ ЧЕТКО БУДЕТ РАБОТАТЬ ТО ЛУЧШЕ УДАЛИТЬ
// "clone": "2.1.2",
// "deep-object-diff": "1.1.0",
export const normalizeResumeOnGet = (data) => {
  //if (data) return data;
  /**
   * @description нужно для диффа - чтобы была видна разница
   * @function clone
   */
  let steps = clone(data);
  // debugger; // eslint-disable-line
  // ======== medicalRestrictions ===============
  if (steps?.medicalRestrictions?.length) {
    steps.medicalRestrictions = steps.medicalRestrictions.map(getElId);
  }
  // ============= extractFileUtls ==============
  steps = extractFilesUrls(steps, fileObjectFields);
  // ============= function =====================
  const desiredFunction = get(steps, 'application[0].desiredFunction');
  if (desiredFunction?.length) {
    const desiredFunctionArr = [];
    desiredFunction.forEach(({ order, functionId }) => {
      desiredFunctionArr[order] = functionId;
    });
    steps.application[0].desiredFunction = desiredFunctionArr;
  }
  // ============================================
  const languages = get(steps, 'languages');
  languages
    .sort((a, b) => a.order - b.order)
    .forEach((el, index) => {
      steps.languages[index]['lang'] = el.langId;
      steps.languages[index]['level'] = el.levelId;
    });

  const englishLevel = get(steps, 'englishLevel');
  steps.englishLevel = englishLevel?.id;
  // ============================================

  const photo = get(steps, 'photo');
  if (photo) {
    steps.photo = PREFIX + STATIC + photo.url;
  }
  // ===============================================

  const clothesSize = get(steps, 'clothesSize');
  steps.clothesSize = clothesSize?.id || null;

  // ===============================================
  ['passport', 'ITN', 'INILA', 'volunteerBookNumber'].forEach((field) => {
    if (steps[field]) {
      steps[field] = steps[field].toString();
    }
  });
  // ===============================================

  const haveVolunteerExperience = get(steps, 'haveVolunteerExperience');
  if (
    haveVolunteerExperience === undefined ||
    haveVolunteerExperience === null ||
    haveVolunteerExperience === true
  ) {
    steps.haveVolunteerExperience = 'true';
  }
  if (haveVolunteerExperience === false) {
    steps.haveVolunteerExperience = 'false';
  }

  const haveTeamleadExperience = get(steps, 'haveTeamleadExperience');
  if (
    haveTeamleadExperience === undefined ||
    haveTeamleadExperience === null ||
    haveTeamleadExperience === true
  ) {
    steps.haveTeamleadExperience = 'true';
  }
  if (haveTeamleadExperience === false) {
    steps.haveTeamleadExperience = 'false';
  }
  const isInVolunteerCenter = get(steps, 'isInVolunteerCenter');
  if (
    isInVolunteerCenter === undefined ||
    isInVolunteerCenter === null ||
    isInVolunteerCenter === true
  ) {
    steps.isInVolunteerCenter = 'true';
  }
  if (isInVolunteerCenter === false) {
    steps.isInVolunteerCenter = 'false';
  }

  const isMemberOfPrefessionals = get(steps, 'isMemberOfPrefessionals');
  if (
    isMemberOfPrefessionals === undefined ||
    isMemberOfPrefessionals === null ||
    isMemberOfPrefessionals === false
  ) {
    steps.isMemberOfPrefessionals= 'false';
  }
  if (isMemberOfPrefessionals === true) {
    steps.isMemberOfPrefessionals = 'true';
  }

  const isMemberOfStudSquad = get(steps, 'isMemberOfStudSquad');
  if (
    isMemberOfStudSquad === undefined ||
    isMemberOfStudSquad === null ||
    isMemberOfStudSquad === false
  ) {
    steps.isMemberOfStudSquad= 'false';
  }
  if (isMemberOfStudSquad === true) {
    steps.isMemberOfStudSquad = 'true';
  }

  const isMemberOfMovementFirst = get(steps, 'isMemberOfMovementFirst');
  if (
    isMemberOfMovementFirst === undefined ||
    isMemberOfMovementFirst === null ||
    isMemberOfMovementFirst === false
  ) {
    steps.isMemberOfMovementFirst= 'false';
  }
  if (isMemberOfMovementFirst === true) {
    steps.isMemberOfMovementFirst = 'true';
  }

  const isMedicialRestrictions = get(steps, 'isMedicialRestrictions');
  if (
    isMedicialRestrictions === undefined ||
    isMedicialRestrictions === null ||
    isMedicialRestrictions === false
  ) {
    steps.isMedicialRestrictions = 'false';
  }
  if (isMedicialRestrictions === true) {
    steps.isMedicialRestrictions = 'true';
  }

  // ===============================================
  const additionalFunctionTerms = get(steps, 'additionalFunctionTerms');
  if (
    additionalFunctionTerms === undefined ||
    additionalFunctionTerms === null ||
    additionalFunctionTerms === true
  ) {
    steps.additionalFunctionTerms = 'true';
  }
  if (additionalFunctionTerms === false) {
    steps.additionalFunctionTerms = 'false';
  }

  const desiredPosition = get(steps, 'application[0].desiredPosition');
  if (desiredPosition) {
    const checkedArr = [];
    desiredPosition.forEach((el) => {
      if (el.id) {
        checkedArr[el.id] = el.id;
      }
    });
    steps.application[0].desiredPosition = checkedArr;
  }
  if (!steps.education.length) {
    steps.education.push({});
  }
  // if (!steps.languages.length) {
  //   steps.languages.push({});
  // }
  // if (!steps.experience.length) {
  //   steps.experience.push({});
  // }
  if (!steps.volunteerExperience.length) {
    steps.volunteerExperience.push({});
  }
  if (!steps.teamleadExperience.length) {
    steps.teamleadExperience.push({});
  }

  //  if (steps.citizenship) {
  //     debugger; // eslint-disable-line
  //     steps.citizenshipValue = steps.citizenship.value;
  //     steps.citizenship = steps.citizenship.id;
  //   }
  // if (steps.region) {
  //   steps.region = steps.region.id;
  // }
  if (steps.registrationAddrCity) {
    steps.registrationAddrRegion = steps.registrationAddrCity.region;
    steps.registrationAddrCountry = steps.registrationAddrCity.country;
  }
  if (steps.factAddrCity) {
    steps.factAddrRegion = steps.factAddrCity.region;
    steps.factAddrCountry = steps.factAddrCity.country;
  }
  /**
   * @description если хотите посмотреть Дифф того что было и того что стало
   * то надо раскомменить это и импорт
   */
  // console.log('OnGet', diff(data, steps), { steps });
  return steps;
};
