import { notificationsConstants } from '../constants/notifications';

export const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key;
  return {
    type: notificationsConstants.ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || Date.now(),
    },
  };
};

export const closeSnackbar = (key) => ({
  type: notificationsConstants.CLOSE_SNACKBAR,
  dismissAll: !key,
  key,
});

export const removeSnackbar = (key) => ({
  type: notificationsConstants.REMOVE_SNACKBAR,
  key,
});
