import React, { FC, ReactElement, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { checkUnknownTranslationWarn } from '../../utils/utils';

interface IFMessage {
  defaultText?: string;
  id: string;
  children?: (chunks: ReactNode) => ReactNode;
}

const FMessage: FC<IFMessage> = ({ children, defaultText, id }) => {
  const intl = useIntl();
  if (checkUnknownTranslationWarn(intl.messages, id, intl.locale)) {
    return <>{defaultText ? defaultText : ''}</>;
  } else {
    return (
      <FormattedMessage id={id} defaultMessage={intl.messages[id]}>
        {(text) => (
          <>
            {children ? children(text) : text}
          </>
        )}
      </FormattedMessage>
    );
  }
};

export default FMessage;
