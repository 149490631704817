import {
  Button,
  Grid,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
  FC,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeInterviewStatus,
  deleteInerviewSlot,
  getInterviewSlots,
  removeInterviewDateInfo,
  selectInerviewSlot,
} from '../../../js/actions/schedule';
import { IRootState } from '../../pages/Cabinet';
import Modal from '../../../rootComponents/Modal/Modal';
import BtnGradient from '../../../rootComponents/Btn/BtnGradient';
import { useIntl } from 'react-intl';
import moment from 'moment';
import ResponseModal from './ResponseModal';
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';

const localeMap: any = {
  ru: ruLocale,
  en: enLocale,
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  slotsContent: {
    alignContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },
  slotLabel: {
    textDecoration: 'underline',
  },
  slot: {
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 10px',
    marginBottom: 10,
  },
  dialogContent: {
    padding: 20,
  },
  title: {
    marginBottom: 20,
  },
  btn: {
    marginTop: 20,
  },
  loader: {
    // width: '100%',
    margin: '0 10px',
  },
}));

interface AppointmentStatus {
  id: number;
  description: string;
  emailNotificationEnabled: boolean;
}

interface Appointment {
  application: {
    id: number;
    status: AppointmentStatus;
    employeeId: number;
  };
}

const InterviewSchedule = () => {
  const [date, setDate] = useState<Date | null>(moment().toDate());
  const [selectedTime, setSelectedTime] = useState(undefined);
  const [info, setInfo] = useState<Appointment | undefined>(undefined);
  const [id, setId] = useState(undefined);
  const {
    interviewersSlots,
    loading,
    interviewStatus,
    interviewDateInfo,
  } = useSelector((state: IRootState) => state.schedule);

  const { locale } = useSelector((state: any) => state.lang);
  const dispatch = useDispatch();
  const { formatMessage: fm } = useIntl();
  const classes = useStyles();

  useEffect(() => {
    if (date) {
      dispatch(
        getInterviewSlots({
          startDate: moment.utc(date).set({ hour: 1 }).toDate(),
          endDate: moment.utc(date).set({ hour: 23 }).toDate(),
        }),
      );
    }
  }, [date]);

  const handleOpenConfirmModal = useCallback((time) => {
    setSelectedTime(time);
  }, []);

  const handleOpenInfoModal = useCallback((time, appointment, id) => {
    setInfo(appointment);
    setSelectedTime(time);
    setId(appointment?.id);
  }, []);

  const handleCloseResponseModal = useCallback(() => {
    dispatch(changeInterviewStatus(''));
    dispatch(removeInterviewDateInfo());
    dispatch(
      getInterviewSlots({
        startDate: moment.utc(date).hour(1).toDate(),
        endDate: moment.utc(date).hour(24).toDate(),
      }),
    );
  }, [date]);

  const handleSendSelectedSlot = useCallback(
    (selectedDate: Date | string) => {
      if (selectedDate) {
        dispatch(
          selectInerviewSlot(moment(selectedDate).utc().toISOString(true)),
        );
      }
      setSelectedTime(undefined);
    },
    [date],
  );

  const handleDeleteSelectedSlot = useCallback((id) => {
    if (id) {
      dispatch(deleteInerviewSlot(id));
      setTimeout(() => {
        dispatch(
          getInterviewSlots({
            startDate: moment.utc(date).hour(1).toDate(),
            endDate: moment.utc(date).hour(23).toDate(),
          }),
        );
      }, 1000);
    }
    setSelectedTime(undefined);
    setInfo(undefined);
    setId(undefined);
  }, []);

  const handleCloseModal = useCallback(() => {
    setSelectedTime(undefined);
    setInfo(undefined);
    setId(undefined);
  }, []);

  const interviewDialogContent = useMemo(() => {
    return (
      <div className={classes.dialogContent}>
        <Typography variant='h5' align='center' className={classes.title}>
          {fm({ id: 'calendar.dialog.interview.title' })}
        </Typography>
        <Typography>
          {`${fm({ id: 'calendar.dialog.interview.text' })}
          ${new Date(selectedTime || '').toLocaleString(locale, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })}
          ${fm({ id: 'general.in' })}
          ${new Date(selectedTime || '').toLocaleString(locale, {
            hour: 'numeric',
            minute: 'numeric',
          })} ? `}
        </Typography>
        <BtnGradient
          onClick={() => handleSendSelectedSlot(selectedTime || '')}
          className={classes.btn}
        >
          {fm({ id: 'general.confirm' })}
        </BtnGradient>
      </div>
    );
  }, [selectedTime, classes, locale]);

  const interviewInfoDialogContent = useMemo(() => {
    return (
      <div className={classes.dialogContent}>
        <Typography variant='h5' align='center' className={classes.title}>
          {fm({ id: 'calendar.dialog.interview.info.title' })}
        </Typography>
        <Typography>
          {`${fm({ id: 'calendar.dialog.interview.info.text' })}
          ${new Date(selectedTime || '').toLocaleString(locale, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })}
          ${fm({ id: 'general.in' })}
          ${new Date(selectedTime || '').toLocaleString(locale, {
            hour: 'numeric',
            minute: 'numeric',
          })}`}
        </Typography>
        {info && (
          <Typography>
            {`${fm({ id: 'general.status' })}: ${
              info?.application?.status?.description
            }`}
          </Typography>
        )}
        <BtnGradient
          onClick={() => handleDeleteSelectedSlot(id)}
          className={classes.btn}
        >
          {fm({ id: 'general.delete' })}
        </BtnGradient>
      </div>
    );
  }, [selectedTime, classes, locale, info, id]);

  return (
    <div className={classes.container}>
      <div>
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={localeMap[locale.toLowerCase()]}
        >
          <DatePicker
            value={date}
            onChange={setDate}
            variant='static'
            disablePast
            views={['date']}
          />
        </MuiPickersUtilsProvider>
      </div>
      <Grid container className={classes.slotsContent}>
        {loading ? (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <LinearProgress className={classes.loader} />
          </Grid>
        ) : interviewersSlots.length ? (
          interviewersSlots?.map((el) => {
            const { startDate, endDate, appointment, id, slotsCount } = el;
            if (appointment) {
              return (
                <Grid item lg={4} key={id} className={classes.slot}>
                  <Button
                    onClick={() => handleOpenInfoModal(startDate, appointment, id)}
                    color='primary'
                    variant='contained'
                    disabled={
                      moment().valueOf() > moment(startDate).valueOf()
                    }
                  >
                    <Typography align='center' className={classes.slotLabel}>
                      {moment(startDate).format('HH:mm')} - {moment(endDate).format('HH:mm')}
                    </Typography>
                  </Button>
                </Grid>
              );
            }
            return (
              <Grid item lg={4} key={startDate} className={classes.slot}>
                <Button
                  onClick={() => handleOpenConfirmModal(startDate)}
                  color={selectedTime === startDate ? 'primary' : 'default'}
                  disabled={
                    moment().valueOf() > moment(startDate).valueOf() ||
                    slotsCount < 1 || moment(startDate).diff(moment(), 'milliseconds') < 3600000
                  }
                >
                  <Typography align='center' className={classes.slotLabel}>
                    {moment(startDate).format('HH:mm')} -{' '}
                    {moment(endDate).format('HH:mm')}
                  </Typography>
                </Button>
              </Grid>
            );
          })
        ) : (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography align='center' className={classes.title}>
              {fm({ id: 'calendar.interview.list.empty' })}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Modal open={!!selectedTime || !!info} onClose={() => handleCloseModal()}>
        {info ? interviewInfoDialogContent : interviewDialogContent}
      </Modal>
      <ResponseModal
        open={!!interviewStatus}
        onClose={handleCloseResponseModal}
        status={interviewStatus}
        info={interviewDateInfo}
      />
    </div>
  );
};

export default memo(InterviewSchedule);
