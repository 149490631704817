import { history } from '../store/configureStore';
import { resumeConstants } from '../constants/resume';

/**
 * @description переходим на Новости после успешной или неуспешной сменыЛогина
 */
const resumeMiddleware = (_store) => (next) => (action) => {
  const result = next(action);

  const { READY_MODAL_CLOSE } = resumeConstants;

  if (action.type === READY_MODAL_CLOSE) {
    history.push('/cabinet/news');
  }

  return result;
};

export default resumeMiddleware;
