import React, { FC } from 'react';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 0,
    width: '100%',
    height: 2,
  },
}));

const Loader: FC = () => {
  const classes = useStyles();
  return <LinearProgress className={classes.root} />;
};

export default Loader;
