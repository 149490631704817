import { listsConstants } from '../constants/lists';
import { ListsActions } from '../actions/lists';
// TODO: удалить все закоментированное
interface IListsState {
  loading: boolean;
  error: string;
  [key: string]: any;
}

const initialState: IListsState = {
  loading: false,
  error: '',
  // function: functionList ,
  // position: positionList,
  // 'medical-restrictions': medicalList
};

export default (state = initialState, action: ListsActions): IListsState => {
  const { payload } = action;
  switch (action.type) {
    case listsConstants.GET_LIST_FETCHING:
      return {
        ...state,
        loading: true,
      };
    case listsConstants.GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        [payload.name]: payload.data,
        error: '',
      };
    case listsConstants.GET_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.type,
      };
    default:
      return state;
  }
};
