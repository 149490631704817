import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { object, string, bool, elementType } from 'prop-types';
import RouteContent from './RouteContent';
import useStoreAuth from '@hooks/useStoreAuth';

const propTypes = {
  path: string.isRequired,
  exact: bool,
  store: object.isRequired,
  component: elementType.isRequired,
  formattedTitle: string.isRequired,
  className: string.isRequired,
  direction: string,
};

const defaultProps = {
  exact: false,
  direction: 'row',
};

const PublicRoute = ({
  store,
  component: Component,
  formattedTitle,
  className,
  direction,
  ...rest
}) => {
  const isAuth = useStoreAuth(store);
  return (
    <Route
      {...rest}
      component={(componentProps) => {
        // не давать зайти по этим урлам если загогинены
        if (isAuth && ['/register', '/login', '/home'].includes(rest.path)) {
          return <Redirect to='/summary' />;
        } else {
          return (
            <RouteContent
              direction={direction}
              component={Component}
              formattedTitle={formattedTitle}
              className={className}
              componentProps={componentProps}
            />
          );
        }
      }}
    />
  );
};

PublicRoute.propTypes = propTypes;
PublicRoute.defaultProps = defaultProps;

export default PublicRoute;
