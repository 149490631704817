import React from 'react'

import * as dates from 'date-arithmetic'
import {navigate} from './utils/constants'
import TimeGrid from 'react-big-calendar/lib/TimeGrid'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {IRootState} from '../../pages/Cabinet'

const range = (date: any) => {
  const start = date
  const end = dates.add(start, 2, 'day')

  let current = start
  const range = []

  while (dates.lte(current, end, 'day')) {
    range.push(current)
    current = dates.add(current, 1, 'day')
  }

  return range
}

const ThreeDaysWeek = (props: any) => {
  // console.log(
  //   'ThreeDaysWeekProps', props
  // )

  const {locale} = useSelector((state: IRootState) => state.lang);

  const loc = locale === 'EN' ? 'en-gb' : locale;
  moment.locale(loc);

  return <TimeGrid {...props} range={range(props.date)} eventOffset={15} />

}



ThreeDaysWeek.navigate = (date: string, action: string) => {
  switch (action) {
    case navigate.PREVIOUS:
      return dates.add(date, -3, 'day')

    case navigate.NEXT:
      return dates.add(date, 3, 'day')

    default:
      return date
  }
}

ThreeDaysWeek.title = (date: any) => {
  return ` ${moment(date).format('dd DD MMMM')} - ${moment(date).add(2, 'days').format('dd DD MMMM')}`
}

export default ThreeDaysWeek
