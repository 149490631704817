import { req } from '../setup';
//import i18n from '../../../fake-dictionary/fake-i18n';

export type I18n = {
  [key: string]: string;
};

const translations = {
  get(): Promise<I18n> {
    return req.get('/i18n').then((res) => res.data);
  },
};

export default translations;
