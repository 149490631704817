// import { Register } from '../routes/volunteer';

import { transliteration } from '../../../utils/transliteration';

const translitFields = {
  translitLastname: 'lastname',
  translitName: 'name',
  translitPatronymic: 'patronymic',
};

const translite = transliteration();

/**
 * @description вроде я это сделал так как бэкенд тупой и на английском языке ему
 * тоже нужны все-все поля которые и на русском
 */
export const normalizeRegister = (data) => {
  if (data.shouldSendNotifications === undefined) {
    data.shouldSendNotifications = false;
  }
  Object.keys(translitFields).forEach((field) => {
    if (!data[field] || (data[field] && !data[field].trim())) {
      data[field] = translite(data[translitFields[field]]);
    }
  });
  return data;
};
