import {Box, Button, makeStyles, Typography} from '@material-ui/core';
import React, {useState} from 'react';
import {Document, Page} from 'react-pdf';
import {pdfjs} from 'react-pdf';
import {useSelector} from 'react-redux';
import {IRootState} from '../pages/Cabinet';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const useStyles = makeStyles({
  button: {
    // height: 30,
    // width: 30,
    // borderRadius: '50%'
  }
})


const ReactPdf = ({file}: any) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const classes = useStyles()

  const {height, width} = useSelector((state: IRootState) => state.cabinet.screenSize)

  function onDocumentLoadSuccess({numPages}: any) {
    setNumPages(numPages);
  }

  const renderFooter = (
    <Box className={classes.button} display='flex' justifyContent='space-evenly' alignItems='center' m={10}>
      {pageNumber > 1 ?
        <Button variant='outlined' onClick={() => setPageNumber(pageNumber - 1)} color='secondary'>
          {pageNumber - 1}
        </Button>
        :
        <Button variant='outlined' color='secondary'>
          {'!'}
        </Button>

      }
      <Typography>
        {pageNumber} / {numPages}
      </Typography>
      {pageNumber < numPages ?
        <Button variant='outlined' className={classes.button} onClick={() => setPageNumber(pageNumber + 1)} >
          {pageNumber + 1}
        </Button>
        :
        <Button variant='outlined' className={classes.button}  >
          {'!'}
        </Button>

      }
    </Box>
  )
  return (
    <div>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page height={height}
          width={width} pageNumber={pageNumber} />

      </Document>
      {renderFooter}
    </div>
  );
}

export default ReactPdf
