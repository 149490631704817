import { req } from '../setup';

class ApiUserContacts {
  private key: string;
  constructor() {
    this.key = '/Employee/contacts';
  }

  getUsersContacts = async () => {
    return req
      .get(this.key)
      .then((res) => res)
      .catch((err) => console.log(err, 'error'));
  };
}

const apiUserContacts = new ApiUserContacts();

export default apiUserContacts;
