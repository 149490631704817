import { resumeConstants } from '@constants/resume';
import { volunteerConstants } from '../constants/volunteer';
import { cabinetConstants } from '../constants/cabinet';
import { RESUME_ACTIVE_STATUS_LIST } from '../constants/resume';
// TODO: в другом месте объяснил как можно сделать чтобы выпилить этот Редьюсер
// думаю он лишний - нужен только для заполнения начально резюме
// читать src\js\actions\resume.js - 24
const initialState = {
  loading: false,
  steps: {},
  isPublish: false,
  readyModalOpen: false,
  failModalOpen: false,
  isSaved: false,
  error: '',
  publishError: false,
  shouldSendNotifications: false,
  isResumeDisabled: false,
};

export default (state = initialState, action = {}) => {
  const { payload } = action;
  switch (action.type) {
    case resumeConstants.RESUME_GET_AFTER_LOGIN_FETCHING:
    case resumeConstants.RESUME_GET_FETCHING:
    case resumeConstants.RESUME_EDIT_FETCHING:
    case resumeConstants.RESUME_SAVE_FETCHING:
    case resumeConstants.RESUME_PUBLISH_FETCHING:
    case volunteerConstants.UPDATE_CONTACTS_FETCHING:
      return {
        ...state,
        loading: true,
      };
    case resumeConstants.RESUME_GET_AFTER_LOGIN_SUCCESS:
    case resumeConstants.RESUME_GET_SUCCESS: {
      let published = false;
      if (payload?.application?.[0].status.description === 'PUBLISHED') {
        published = true;
      }

      let isResumeDisabled = false;
      if (
        !RESUME_ACTIVE_STATUS_LIST.includes(payload?.application?.[0].status.id)
      ) {
        isResumeDisabled = true;
      }
      return {
        ...state,
        steps: payload,
        loading: false,
        isPublish: published,
        shouldSendNotifications: payload.shouldSendNotifications,
        error: '',
        isResumeDisabled,
      };
    }

    case resumeConstants.RESUME_SAVE_SUCCESS: {
      let published = false;
      if (payload.application[0].status.description === 'PUBLISHED') {
        published = true;
      }

      let isResumeDisabled = false;
      if (
        !RESUME_ACTIVE_STATUS_LIST.includes(payload?.application?.[0].status.id)
      ) {
        isResumeDisabled = true;
      }

      return {
        ...state,
        loading: false,
        isSaved: true,
        isPublish: published,
        steps: payload,
        error: '',
        isResumeDisabled,
      };
    }

    case resumeConstants.RESUME_EDIT_SUCCESS:
      return {
        ...state,
        steps: payload,
        loading: false,
        //readyModalOpen: true,
        error: '',
      };
    case volunteerConstants.UPDATE_CONTACTS_SUCCESS:
      return {
        ...state,
        steps: { ...state.steps, payload },
        loading: false,
        error: '',
      };
    case resumeConstants.RESUME_PUBLISH_SUCCESS: {
      let published = false;
      if (payload.application[0].status.description === 'PUBLISHED') {
        published = true;
      }

      let isResumeDisabled = false;
      if (
        !RESUME_ACTIVE_STATUS_LIST.includes(payload?.application?.[0].status.id)
      ) {
        isResumeDisabled = true;
      }

      return {
        ...state,
        loading: false,
        readyModalOpen: true,
        isPublish: published,
        steps: payload,
        error: '',
        isResumeDisabled,
      };
    }
    case volunteerConstants.LOGOUT_SUCCESS:
    case cabinetConstants.GO_TO_LOGIN:
      return {
        ...state,
        ...initialState,
      };

    case resumeConstants.RESUME_GET_AFTER_LOGIN_FAILURE:
    case resumeConstants.RESUME_GET_FAILURE:
    case resumeConstants.RESUME_SAVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case resumeConstants.RESUME_EDIT_FAILURE:
      return {
        ...state,
        loading: false,
        //failModalOpen: true,
        error: '',
      };
    case resumeConstants.RESUME_PUBLISH_FAILURE:
    case resumeConstants.FAIL_MODAL_OPEN:
      // TODO: это я не использую. но хорошо бы было. не было времени и мыслей
      // куда пристроить возможные ошибки с бэка для рендера
      return {
        ...state,
        loading: false,
        failModalOpen: true,
        error: payload,
      };

    case resumeConstants.READY_MODAL_CLOSE:
      return {
        ...state,
        error: '',
        readyModalOpen: false,
      };
    case resumeConstants.FAIL_MODAL_CLOSE:
      return {
        ...state,
        error: '',
        failModalOpen: false,
      };
    case resumeConstants.RESUME_SET_PUBLISH_ERROR:
      return {
        ...state,
        publishError: true,
      };
    default:
      return state;
  }
};
