/**
 * @description бэкенду нужны не айдишки, а объекты с айдишками
 * @param el айдишка
 */
export const saveIdAsObj = (el) => (el && { id: +el }) || el;
/**
 * @description с бэка приходят объекты, а я хочу айдишки
 * @param el.id айдишка
 */
export const getElId = (el) => +el?.id || el;
/**
 * @description нормализация для бэка
 * @param type number | trim
 */
export const formatToType = (type, data, fields, inArray) => {
  if (!inArray) {
    fields.forEach((key) => {
      if (data[key]) {
        if (type === 'number') {
          data[key] = !isNaN(+data[key]) ? +data[key] : data[key];
        } else if (type === 'trim') {
          data[key] =
            typeof data[key] === 'string' ? data[key].trim() : data[key];
        }
      }
    });
  } else {
    fields.forEach(([key, objFields]) => {
      if (data[key] && Array.isArray(data[key]) && data[key].length) {
        data[key] = data[key].map((el) => {
          const arrData = { ...el };
          objFields.forEach((objKey) => {
            if (arrData[objKey]) {
              if (type === 'number') {
                arrData[objKey] = !isNaN(+arrData[objKey])
                  ? +arrData[objKey]
                  : arrData[objKey];
              } else if (type === 'trim') {
                arrData[objKey] =
                  typeof arrData[objKey] === 'string'
                    ? arrData[objKey].trim()
                    : arrData[objKey];
              }
            }
          });
          return arrData;
        });
      }
    });
  }
  return data;
};
/**
 * @description нормализация пустых полей
 * @param data все данные
 * @param fields[0] ключ в данных
 * @param fields[1] обязательные поля
 * @param fields[2] необязательные поля
 * @description это для частичного сохранения массивов в форме,
 * когда можно не все поля сразу заполнить и сохранить
 * функция дозаполнит то, что обязательно
 */
export const fillEmptyRequiredObjFields = (data, fields) => {
  fields.forEach(([key, requiredFields, notRequiredFields]) => {
    if (data[key] && Array.isArray(data[key]) && data[key].length) {
      data[key] = data[key].map((el) => {
        const arrData = { ...el };
        const hasOptionalValues = notRequiredFields.some(
          (optionalKey) => !!arrData[optionalKey],
        );
        if (hasOptionalValues) {
          requiredFields.forEach((requiredKey) => {
            if (!arrData[requiredKey]) {
              arrData[requiredKey] = '';
            }
          });
        }
        return arrData;
      });
    }
  });
  return data;
};
/**
 * @description регуляока для получения названия файла из полного пути
 */
const fileRegex = /(\/.*\/)([0-9a-z]+-){5}(.*\..{3})/i;
/**
 * @param str полный путь к файлу
 */
export const extractFileNameFromPath = (str) => {
  const res = str.match(fileRegex);
  if (Array.isArray(res) && res.length === 4) {
    return res[3].substr(0, 10) + '...';
  }
  return '';
};
/**
 * @description когда сохраняется файл, с бэка приходит некрасивый объект. мне оттуда нужно только 'url'
 * @param data все данные
 * @param fields полей, в которых сохраняются объекты с 'url'
 */
export const extractFilesUrls = (data, fields) => {
  fields.forEach((key) => {
    if (data[key] && data[key].url) {
      data[key] = extractFileNameFromPath(data[key].url);
    }
  });
  return data;
};
