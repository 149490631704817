import React from 'react';
import {useSelector} from 'react-redux';
import {createStyles, makeStyles, Theme, withStyles} from '@material-ui/core/styles';
import {Grid, Typography, Divider, LinearProgress} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';


import {useIntl} from 'react-intl';
import {IRootState} from './Cabinet';
import {iFMessage} from '../../utils/utils';
import Faq, {questions} from '../components/Faq';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      maxHeight: 570,
      position: 'relative',
      display: 'flex',
      height: '100%',
      marginTop: 20,
      overflow: 'auto',
    },

    loadingIcon: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
    },
    container: {
      padding: 30,
      backgroundColor: 'white',
      position: 'relative',
      minHeight: '80vh',
      [theme.breakpoints.down('xs')]: {
        padding: 10,
      },
    },
    subHeader: {
      width: '100%',
      padding: '10px 0',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    documentBox: {
      maxHeight: 600,
      overflow: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 20,
    },
    subtitle: {
      margin: '10px 0',
    },
    divider: {
      margin: '20px 0',
    },
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  }),
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} >
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const FAQPage = (): React.ReactElement => {
  const classes = useStyles();
  const intl = useIntl();

  const [value, setValue] = React.useState(0);

  const handleChange = (_event: React.ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
  };

  const stateVolunteer = useSelector((state: IRootState) => state.volunteer)
  const volunteerLoading = stateVolunteer.loading

  const iMessage = iFMessage(intl)

  const StyledTab = withStyles(({palette}) => ({
    wrapper: {
      flexDirection: 'row',
      justifyContent: 'left',
    },

    selected: {
      color: palette.primary.main,
    },
    textColorInherit: {
      opacity: 1
    }

  }))(Tab)


  const {interviewQuestions, forumQuestions, registrationQuestions, resumeQuestions} = questions

  return (
    <div className={classes.container}>
      {volunteerLoading && <LinearProgress className={classes.loadingIcon} />}
      <Typography variant='h5' >
        {iMessage('page.faq.title.faq').toUpperCase()}
      </Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={3} >
        <Grid item xs={12} sm={4} md={3}
        >
          <Typography variant='h6' color='primary'>
            Темы:
          </Typography>
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            aria-label="FAQ"
            className={classes.tabs}

          >
            <StyledTab label="Анкета" {...a11yProps(0)} />
            <StyledTab label="Регистрация" {...a11yProps(1)} />
            <StyledTab label="Собеседование" {...a11yProps(2)} />
            <StyledTab label="Форум" {...a11yProps(3)} />
          </Tabs>
        </Grid>
        <Grid item xs={12} sm={8} md={9}
        >
          <div className={classes.root}>
            <TabPanel value={value} index={0}>
              <Faq questions={resumeQuestions} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Faq questions={registrationQuestions} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Faq questions={interviewQuestions} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Faq questions={forumQuestions} />
            </TabPanel>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(FAQPage);
