import { volunteerConstants } from '../constants/volunteer';
import { history } from '../store/configureStore';
/**
 * @description переходим на стр логина после успешной регистрации
 */
const registerMiddleware = () => (next) => (action) => {
  const result = next(action);
  if (action.type === volunteerConstants.REGISTER_THANKYOU_CLOSE) {
    history.push('/login');
  }
  return result;
};

export default registerMiddleware;
