import React from 'react';
import { Route } from 'react-router-dom';
import { object, string, bool, elementType } from 'prop-types';
import Exit from '@components/common/Exit/Exit';
import RouteContent from './RouteContent';
import useStoreAuth from '@hooks/useStoreAuth';

const propTypes = {
  path: string.isRequired,
  exact: bool,
  store: object.isRequired,
  component: elementType.isRequired,
  formattedTitle: string.isRequired,
  className: string.isRequired,
};

const defaultProps = {
  exact: false,
};

const PrivateRoute = ({
  store,
  component: Component,
  formattedTitle,
  className,
  ...rest
}) => {
  const isAuth = useStoreAuth(store);
  return (
    <Route
      {...rest}
      component={(componentProps) => {
        if (isAuth) {
          return (
            <>
              <RouteContent
                component={Component}
                formattedTitle={formattedTitle}
                className={className}
                componentProps={componentProps}
              />
            </>
          );
        } else {
          return <Exit />;
        }
      }}
    />
  );
};

PrivateRoute.propTypes = propTypes;
PrivateRoute.defaultProps = defaultProps;

export default PrivateRoute;
