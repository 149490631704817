import { req } from '../setup';
import { normalizeRegister } from '../helpers/normalizeRegister';

export type Register = {
  name: string;
  translitName: string;
  patronymic: string;
  translitPatronymic: string;
  lastname: string;
  translitLastname: string;
  email: string;
  phone: string;
  PDAgreement: boolean;
  shouldSendNotifications: boolean;
  recaptcha: string;
};

type RegisterRolesReturn = {
  description: string;
  id: number;
  features: {
    value: string;
    description: string;
    id: number;
  }[];
};

type RegisterReturn = Register & {
  id: number;
  login: string;
  password: string;
  passwordHash: string;
  status: number;
  createdAt: string;
  updatedAt: string;
  token: string;
  roles: RegisterRolesReturn[];
};

type Login = {
  username: string;
  password: string;
  recaptcha: string;
};
type NewLogin = {
  type: number;
  verifedContact: string;
  newVerifed: string;
};
type CodeWithEmail = {
  code: string;
  email: string;
};
type CodeWithPhone = {
  code: string;
  phone: string;
};

type EmailChange = {
  code: string;
  oldEmail: string;
  email: string;
};
type PhoneChange = {
  code: string;
  oldEmail: string;
  email: string;
};
type PassChange = {
  olsPassword: string;
  newPassword: string;
  email: string;
};
export type ProfileSettings = {
  value: boolean;
};
export type NewNewsParamsType = {
  area: number;
  team: number;
  block: number;
  lang: string;
};
export type AllNewsParamsType = {
  page?: number;
  limit?: number;
  lang: string;
  block: number;
  area: number;
  team: number;
};
export type ReadAlertParamType = {
  id: number;
};
export type UpdateContactsType = {
  phoneNumber?: string;
  email?: string;
  vkLink?: string;
  telegram?: string;
};

export type CountriesParamsType = {
  q: string;
};

export type refusePartParamsType = {
  id: number;
};
export type refusePartBodyType = {
  rejectReason: number;
  rejectReasonText: string;
};

export type feedbackBodyType = {
  message: string;
  isAnonimous: boolean;
}

// TODO: дотипизировать
// type LoginBySocial = {};

// type ResetPass = {};

// type CheckPassToken = {};

// type SetNewPass = {};

// type GetProfile = {};

const volunteer = {
  register(data: Register): Promise<RegisterReturn> {
    return req
      .post('/registration', normalizeRegister(data))
      .then((res) => res.data);
  },
  login(data: Login): Promise<any> {
    return req.post('/auth/login', data).then((res) => res.data);
  },
  loginBySocial(type: any, params: any): Promise<any> {
    return req.get(`${type}`, { params }).then((res) => res.data);
  },
  resetPass(data: any): Promise<any> {
    return req
      .post('/user/set-reset-password-token', data)
      .then((res) => res.data);
  },
  generateCode(data: NewLogin): Promise<any> {
    return req.post('/user/generate-code', data).then((res) => res.data);
  },
  verifyEmail(data: CodeWithEmail): Promise<any> {
    return req.post('/user/email/verify', data).then((res) => res.data);
  },
  verifyPhone(data: CodeWithPhone): Promise<any> {
    return req.post('/user/phone/verify', data).then((res) => res.data);
  },
  changeEmail(data: EmailChange): Promise<any> {
    return req.post('/user/email/change', data).then((res) => res.data);
  },
  changePhone(data: PhoneChange): Promise<any> {
    return req.post('/user/phone/change', data).then((res) => res.data);
  },
  changePass(data: PassChange): Promise<any> {
    return req.post('/user/change-password', data).then((res) => res.data);
  },
  checkPassToken(token: any): Promise<any> {
    return req.get(`/user/restore-password/s${token}`).then((res) => res.data);
  },
  setNewPass({ token, ...data }: any): Promise<any> {
    return req
      .post(`/user/restore-password/s${token}`, data)
      .then((res) => res.data);
  },
  getProfile(): Promise<any> {
    return req.get('/profile').then((res) => res.data);
  },
  setProfileOption(data: ProfileSettings): Promise<any> {
    return req.put('/Employee/notification', data).then((res) => res.data);
  },

  refuseParticipate(
    params: refusePartParamsType,
    data: refusePartBodyType,
  ): Promise<any> {
    return req.patch(`/application/${params.id}`, data).then((res) => res.data);
  },

  getAllNewsList(params: AllNewsParamsType): Promise<any> {
    return req.get(`/news/list`, { params }).then((res) => res);
  },

  getNewNewsList(data: NewNewsParamsType): Promise<any> {
    // console.log('getNewNewsList.data:', data);
    return req.post('/news/new', data).then((res) => res);
  },

  readNews(id: number): Promise<any> {
    return req.get(`/news/${id}`).then((res) => res);
  },

  getPersonalFilter(): Promise<any> {
    return req.get(`/Employee`).then((res) => res);
  },

  getAlertsList(): Promise<any> {
    return req
      .get('/alerts')
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  },
  updateContacts(data: UpdateContactsType): Promise<any> {
    return req.patch('/Employee', data).then((res) => res);
  },
  getCountries(params: CountriesParamsType): Promise<any> {
    return req.get('/countries', { params }).then((res) => res.data);
  },

  submitFeedback(
    data: feedbackBodyType,
  ): Promise<any> {
    return req.post(`/Employee/feedback`, data).then((res) => res.data);
  },

};

export default volunteer;
