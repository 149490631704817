import React, {useMemo, useState} from 'react';
import {
  Dialog,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
  Slide,
} from '@material-ui/core';
import {Files} from '../pages/EducationPage';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import YouTubeIcon from '@material-ui/icons/YouTube';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
import ViewFileIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import CloseIcon from '@material-ui/icons/Close'
import {TransitionProps} from '@material-ui/core/transitions';
import './DocumentListCard.css'
import ReactPdf from './ReactPdf';
import SlideShow from './SlideShow';
import YoutubeEmbed from './YouTubeEmbed';
import {useSelector} from 'react-redux';
import {IRootState} from '../pages/Cabinet';

const useStyles = makeStyles((theme: Theme) => ({
  circle: {
    width: 50,
    minWidth: 50,
    height: 50,
    margin: 20,
    background: 'linear-gradient(225deg, rgb(76, 78, 130), rgb(96, 167, 149))',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: 30,
  },
  container: {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    backgroundColor: '#ECF6F3',
    position: 'relative',
  },
  textBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 180,
    },
    minHeight: 70,
    overflow: 'hidden',
    marginRight: 10,
    marginBottom: 18,
    marginTop: 5
  },
  downloadBtnBox: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    color: theme.palette.primary.main,
  },
  modalCloseIcon: {
    position: 'fixed',
    right: 20,
    top: 15,
    zIndex: 1000,
    color: '#9d9d9d',
    backgroundColor: 'rgba(232, 232, 232, 0.727)',
    padding: 5,
    '&:hover': {
      color: 'black',
      backgroundColor: 'rgba(235, 234, 234, 0.898)',
    }
  },
  videoIcon: {
    cursor: 'pointer',
    '&:hover': {
      border: '2px solid #16ac08',
    }
  },
  playCircleIcon: {
    '&:hover': {
      color: '#2c6fec'
    }
  },
  modalHeader: {
    position: 'sticky',
  },
  iconRoot: {
    padding: 5
  },
  link: {
    marginTop: 20,
    color: 'white',
    textDecoration: 'none',
    '&:hover': {
      color: '#77c767',
      textDecoration: 'none',
    }
  },
  player: {
    position: 'absolute',
    zIndex: 1000,
    margin: '0 auto'
  },
  playerCloseIcon: {
    // position: 'relative',
    top: '-10px',
    right: '-10px',
  }
}));



const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {children?: React.ReactElement},
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  document: Files;
}

const DocumentListCard: React.FC<Props> = ({document}) => {
  const classes = useStyles();
  const {isYoutubeVideo, description} = document
  const [viewerOpened, setViewerOpened] = useState(false)
  const {locale} = useSelector((state: IRootState) => state.lang);

  const fileName = document.name[locale === 'RU' ? 'ru' : 'en']


  const youtube_parser = (url: string) => {
    if (!isYoutubeVideo) return
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url?.match(regExp);
    return (match && match[7].length == 11) ? match[7] : url;
  }


  const handleClose = () => setViewerOpened(false)
  const cardName = useMemo(() => {
    const name = fileName?.replace(/\.[^/.]+$/, "") || ''
    if (name.length >= 30) {
      return name.substr(0, 30) + '...';
    }
    return name;
  }, [fileName]);

  const cardDescription = useMemo(() => {
    if (description?.length >= 45) {
      return description?.substr(0, 45) + '...';
    }
    return description;
  }, [description]);

  const link = useMemo(() => {
    return `/api/static${document.static?.url}`;
  }, [document.static?.url]);

  const DialogPaper = ({children}: any) => (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{children}</div>
  )

  const extension = document.static?.ext ? document.static?.ext : fileName?.slice(-3)

  const isPresentation = document.url?.includes('presentation')

  return (
    <Paper className={classes.container} >
      <div className={`${classes.circle} ${isYoutubeVideo && classes.videoIcon}`}>
        {
          document.isYoutubeVideo ?
            <YouTubeIcon onClick={() => setViewerOpened(true)} />
            :
            <DescriptionOutlinedIcon />
        }
      </div>
      <div className={classes.textBox}>
        <Typography variant='body2'>{cardName}</Typography>

        {isYoutubeVideo &&
          <Typography variant='caption'>
            {cardDescription}
          </Typography>
        }

      </div>
      <div className={classes.downloadBtnBox}>
        {(extension === 'pdf' || extension === 'jpg' || isPresentation) &&
          <IconButton
            onClick={() => setViewerOpened(true)}
          >
            <ViewFileIcon />
          </IconButton>
        }

        {isYoutubeVideo ?
          <IconButton
            onClick={() => setViewerOpened(true)}
          >
            <PlayCircleOutline className={classes.playCircleIcon} />
          </IconButton>
          :
          <IconButton href={link} download={`${fileName}${document.static.ext ? `.${document.static.ext}` : ''}`}>
            <GetAppIcon />
          </IconButton>
        }

      </div>

      <Dialog
        fullScreen
        open={viewerOpened}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperComponent={extension === 'pdf' ? Paper : DialogPaper}
      >
        <IconButton
          className={classes.modalCloseIcon}
          edge="start"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        {extension === 'jpg' && <img src={link} alt={fileName} />}
        {extension === 'pdf' && <ReactPdf file={link} />}
        {isPresentation && <SlideShow url={document.url} />}
        {isYoutubeVideo &&
          <YoutubeEmbed embedId={youtube_parser(document.url)} closeViewer={() => setViewerOpened(false)} />
        }
      </Dialog>
    </Paper >
  );
};

export default React.memo(DocumentListCard);
