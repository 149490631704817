import {
  NewNewsParamsType,
  AllNewsParamsType,
  ProfileSettings,
} from './../api/routes/volunteer';
import {
  IAlert,
  NewsItemI,
} from './../../volunteers/components/News/newsTypes';
import { cabinetConstants } from './../constants/cabinet';
import { enqueueSnackbar } from './notifications';
import apiVolunteer from '../api/routes/volunteer';
import apiCabinet from '../api/routes/cabinet.routes';
import apiUserFiles from '../api/routes/apiUserFiles';
import { Files } from '../../volunteers/pages/EducationPage';
import apiUserContacts from '../api/routes/apiUserContacts';
import { IEmployeeData, ITestsSummary } from '../reducers/cabinetReducer';
// import { IFilterForPersonalNews } from '../reducers/cabinetReducer';

/**
 * @description переход на страницу логина
 */

export const goToLogin = (dispatch: any) => {
  return dispatch({ type: cabinetConstants.GO_TO_LOGIN });
};
/**
 * @description переключение настройки уведомлений на странице Профиля
 */
type SwitchProfileSettingType = {
  type: typeof cabinetConstants.SWITCH_PROFILE_SETTING;
  payload: boolean;
};
export const switchProfileSetting = (value: boolean) => (dispatch: any) => {
  return dispatch({
    type: cabinetConstants.SWITCH_PROFILE_SETTING,
    payload: value,
  });
};

/**
 * @description установка ширины и высоты экрана
 */
export type ScreenSizeType = {
  height: number;
  width: number;
};
type SetScreenSize = {
  type: typeof cabinetConstants.SET_SCREEN_SIZE;
  payload: ScreenSizeType;
};
export const setScreenSize = (screenSize: ScreenSizeType) => (
  dispatch: any,
) => {
  return dispatch({
    type: cabinetConstants.SET_SCREEN_SIZE,
    payload: screenSize,
  });
};

/**
 * @description установка текущего пункта меню
 */
type SetTabValue = {
  type: typeof cabinetConstants.SET_TAB_VALUE;
  payload: string;
};
export const setTabValue = (value: string) => (dispatch: any) => {
  return dispatch({
    type: cabinetConstants.SET_TAB_VALUE,
    payload: value,
  });
};

/**
 * @description соединение списка персональных и остальных новостей
 */
type MergeNewsToAll = {
  type: typeof cabinetConstants.MERGE_NEWS_TO_ALL;
  payload: number;
};
export const mergeNewsToAll = () => (dispatch: any) => {
  return dispatch({
    type: cabinetConstants.MERGE_NEWS_TO_ALL,
  });
};
/**
 * @description установка текущего таба новостей (0-персональные, 1-общие)
 */
type SetNewsTabValue = {
  type: typeof cabinetConstants.SET_NEWS_TAB_VALUE;
  payload: number;
};
export const setNewsTabValue = (value: number) => (dispatch: any) => {
  return dispatch({
    type: cabinetConstants.SET_NEWS_TAB_VALUE,
    payload: value,
  });
};

/**
 * @description запрос списка непрочитанных новостей
 */
type GetNewNewsSuccess = {
  type: typeof cabinetConstants.GET_NEWS_SUCCESS;
  payload: NewsItemI[];
};
export const getNewNews = (params: NewNewsParamsType) => async (
  dispatch: any,
) => {
  dispatch({ type: cabinetConstants.GET_NEWS_FETCHING });

  try {
    const res = await apiVolunteer.getNewNewsList(params);
    if (!res.data) {
      throw new Error('NO_NEWS');
    }
    return dispatch({
      type: cabinetConstants.GET_NEWS_SUCCESS,
      payload: res.data,
    });
  } catch (error: any) {
    if (error.response.status === 401) {
      dispatch({ type: cabinetConstants.GO_TO_LOGIN });
    }
    dispatch(
      enqueueSnackbar({
        message: error.message,
        options: {
          autoHideDuration: 5000,
          key: Date.now(), //+ index
          variant: 'error',
        },
      }),
    );
    dispatch({ type: cabinetConstants.GET_NEWS_FAILURE });
  }
};
/**
 * @description запрос списка непрочитанных новостей
 */
type GetUnreadedPersonalNews = {
  type: typeof cabinetConstants.GET_UNREADED_PERSONAL_NEWS_SUCCESS;
  payload: NewsItemI[];
};
export const getUnreadedPersonalNews = (params: NewNewsParamsType) => async (
  dispatch: any,
) => {
  const { team, area, block } = params;
  if (area === 0 || (team === 0 && area === 0 && block === 0)) {
    return dispatch({
      type: cabinetConstants.GET_UNREADED_PERSONAL_NEWS_SUCCESS,
      payload: [],
    });
  }
  dispatch({ type: cabinetConstants.GET_UNREADED_PERSONAL_NEWS_FETCHING });
  try {
    //console.log('getUnreadedPersonalNews.params', params);
    const res = await apiVolunteer.getNewNewsList(params);
    if (!res.data) {
      throw new Error('NO_NEWS');
    }
    return dispatch({
      type: cabinetConstants.GET_UNREADED_PERSONAL_NEWS_SUCCESS,
      payload: res.data,
    });
  } catch (error: any) {
    if (error.response.status === 401) {
      dispatch({ type: cabinetConstants.GO_TO_LOGIN });
    }
    dispatch(
      enqueueSnackbar({
        message: error.message,
        options: {
          autoHideDuration: 5000,
          key: Date.now(), //+ index
          variant: 'error',
        },
      }),
    );
    dispatch({ type: cabinetConstants.GET_NEWS_FAILURE });
  }
};
/**
 * @description регистрация прочтения новости пользователем
 */
type ReadNewsSuccess = {
  type: typeof cabinetConstants.GET_NEWS_SUCCESS;
  payload: number;
};

export const readNews = (id: number) => async (dispatch: any) => {
  dispatch({ type: cabinetConstants.READ_NEWS_FETCHING });
  try {
    await apiVolunteer.readNews(id);

    return dispatch({
      type: cabinetConstants.READ_NEWS_SUCCESS,
      payload: id,
    });
  } catch (error: any) {
    dispatch(
      enqueueSnackbar({
        message: error.message,
        options: {
          autoHideDuration: 5000,
          key: Date.now(), //+ index
          variant: 'error',
        },
      }),
    );
    dispatch({ type: cabinetConstants.READ_NEWS_FAILURE });
  }
};
/**
 * @description запись настройки профиля
 */
type SetProfileSettings = {
  type: typeof cabinetConstants.SET_PROFILE_SETTINGS_SUCCESS;
  payload: boolean;
};

export const setProfileSettings = (data: ProfileSettings) => async (
  dispatch: any,
) => {
  dispatch({ type: cabinetConstants.SET_PROFILE_SETTINGS_FETCHING });
  try {
    const res = await apiVolunteer.setProfileOption(data);

    dispatch({
      type: cabinetConstants.SET_PROFILE_SETTINGS_SUCCESS,
      payload: res.data,
    });
    dispatch(
      enqueueSnackbar({
        message: 'OK',
        options: {
          autoHideDuration: 5000,
          key: Date.now(), //+ index
          variant: 'success',
        },
      }),
    );
  } catch (error: any) {
    if (error.response?.status === 401) {
      dispatch({ type: cabinetConstants.GO_TO_LOGIN });
    }
    dispatch(
      enqueueSnackbar({
        message: error.message,
        options: {
          autoHideDuration: 5000,
          key: Date.now(), //+ index
          variant: 'error',
        },
      }),
    );
    dispatch({ type: cabinetConstants.SET_PROFILE_SETTINGS_FAILURE });
  }
};

/**
 * @description запрос списка уведомлений
 */
type GetAlerts = {
  type: typeof cabinetConstants.GET_ALERTS_SUCCESS;
  payload: IAlert[];
};
export const getAlerts = () => async (dispatch: any) => {
  dispatch({ type: cabinetConstants.GET_ALERTS_FETCHING });
  try {
    const res = await apiVolunteer.getAlertsList();
    const alertArray: IAlert[] = res.data;
    alertArray?.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();
      return dateB - dateA;
    });

    return dispatch({
      type: cabinetConstants.GET_ALERTS_SUCCESS,
      payload: res.data,
    });
  } catch (error: any) {
    if (error.response?.status === 401) {
      dispatch({ type: cabinetConstants.GO_TO_LOGIN });
    }
    dispatch(
      enqueueSnackbar({
        message: error.message,
        options: {
          autoHideDuration: 5000,
          key: Date.now(), //+ index
          variant: 'error',
        },
      }),
    );
    dispatch({ type: cabinetConstants.GET_ALERTS_FAILURE });
  }
};

/**
 * @description регистрация прочтения alert пользователем
 */
type ReadAlert = {
  type: typeof cabinetConstants.READ_ALERT_SUCCESS;
  payload: number;
};

export const readAlert = (id: number) => async (dispatch: any) => {
  dispatch({ type: cabinetConstants.READ_ALERT_FETCHING });
  try {
    await apiCabinet.readAlert({ id });
    return dispatch({
      type: cabinetConstants.READ_ALERT_SUCCESS,
      payload: id,
    });
  } catch (error: any) {
    const errMessage = error.response
      ? `${error.response.status} ${error.response.data.message}`
      : error.message;

    dispatch(
      enqueueSnackbar({
        message: errMessage,
        options: {
          autoHideDuration: 5000,
          key: Date.now(), //+ index
          variant: 'error',
        },
      }),
    );
    dispatch({ type: cabinetConstants.READ_ALERT_FAILURE });
  }
};
/**
 * @description удаление alert пользователем
 */
type DeleteAlert = {
  type: typeof cabinetConstants.DELETE_ALERT_SUCCESS;
  payload: number;
};

export const deleteAlert = (id: number) => async (dispatch: any) => {
  dispatch({ type: cabinetConstants.DELETE_ALERT_FETCHING });
  try {
    await apiCabinet.deleteAlert({ id });
    return dispatch({
      type: cabinetConstants.DELETE_ALERT_SUCCESS,
      payload: id,
    });
  } catch (error: any) {
    const errMessage = error.response
      ? `${error.response.status} ${error.response.data.message}`
      : error.message;

    dispatch(
      enqueueSnackbar({
        message: errMessage,
        options: {
          autoHideDuration: 5000,
          key: Date.now(), //+ index
          variant: 'error',
        },
      }),
    );
    dispatch({ type: cabinetConstants.DELETE_ALERT_FAILURE });
  }
};

/**
 * @description запрос всего списка новостей (с лимитом в параметрах)
 * @params ?page=1 & limit=10 & lang=ru & block=0 & group=0 & team=0
 */
type GetAllNewsSuccess = {
  type: typeof cabinetConstants.GET_ALL_NEWS_SUCCESS;
  payload: NewsItemI[];
};
export const getAllNews = (params: AllNewsParamsType) => async (
  dispatch: any,
) => {
  dispatch({ type: cabinetConstants.GET_ALL_NEWS_FETCHING });
  try {
    const res = await apiVolunteer.getAllNewsList(params);

    return dispatch({
      type: cabinetConstants.GET_ALL_NEWS_SUCCESS,
      payload: res.data,
    });
  } catch (error: any) {
    if (error.response.status === 401) {
      dispatch({ type: cabinetConstants.GO_TO_LOGIN });
    }
    dispatch(
      enqueueSnackbar({
        message: error.message,
        options: {
          autoHideDuration: 5000,
          key: Date.now(), //+ index
          variant: 'error',
        },
      }),
    );
    dispatch({ type: cabinetConstants.GET_ALL_NEWS_FAILURE });
  }
};

/**
 * @description запрос списка персональных новостей
 */

type GetPersonalNews = {
  type: typeof cabinetConstants.GET_PERSONAL_NEWS_SUCCESS;
  payload: NewsItemI[];
};

export const getPersonalNews = (params: AllNewsParamsType) => async (
  dispatch: any,
) => {
  const { team, area, block } = params;
  if (area === 0 || (team === 0 && area === 0 && block === 0)) {
    return dispatch({
      type: cabinetConstants.GET_PERSONAL_NEWS_SUCCESS,
      payload: [],
    });
  }
  dispatch({ type: cabinetConstants.GET_PERSONAL_NEWS_FETCHING });
  try {
    const res = await apiVolunteer.getAllNewsList(params);

    return dispatch({
      type: cabinetConstants.GET_PERSONAL_NEWS_SUCCESS,
      payload: res.data,
    });
  } catch (error: any) {
    if (error.response.status === 401) {
      dispatch({ type: cabinetConstants.GO_TO_LOGIN });
    }
    dispatch(
      enqueueSnackbar({
        message: error.message,
        options: {
          autoHideDuration: 5000,
          key: Date.now(), //+ index
          variant: 'error',
        },
      }),
    );
    dispatch({ type: cabinetConstants.GET_PERSONAL_NEWS_FAILURE });
  }
};

/**
 * @description запрос данных для фильтра персональных новостей
 */

type GetPersonalNewsFilter = {
  type: typeof cabinetConstants.GET_PERSONAL_NEWS_FILTER_SUCCESS;
  payload: AllNewsParamsType; //user_id
};

export const getPersonalNewsFilter = () => async (dispatch: any) => {
  dispatch({ type: cabinetConstants.GET_PERSONAL_NEWS_FILTER_FETCHING });
  try {
    const res = await apiVolunteer.getPersonalFilter();
    const application = res.data.application ? res.data.application : [];
    const team = application[0] ? application[0].team : null;
    // const area = team ? team.areaId : null; //application[0] ? application[0].area : null;
    // const blockId = area ? area.blockId : 0;
    const teamId = team ? team.id : 0;
    const areaId = team ? team.areaId : 0;

    return dispatch({
      type: cabinetConstants.GET_PERSONAL_NEWS_FILTER_SUCCESS,
      payload: {
        // block: blockId,
        team: teamId,
        area: areaId,
      },
    });
  } catch (error: any) {
    if (error.response.status === 401) {
      dispatch({ type: cabinetConstants.GO_TO_LOGIN });
    }
    dispatch(
      enqueueSnackbar({
        message: error.message,
        options: {
          autoHideDuration: 5000,
          key: Date.now(), //+ index
          variant: 'error',
        },
      }),
    );
    dispatch({ type: cabinetConstants.GET_PERSONAL_NEWS_FILTER_FAILURE });
  }
};

export const getFiles = () => (dispatch: any) => {
  dispatch({
    type: cabinetConstants.GET_COMMON_FILES_FETCHING,
  });

  apiUserFiles
    .getFilesList()
    .then((data: Files[]) => {
      const common = data?.filter((file: Files) => file.section?.type === 1);
      const special = data?.filter((file: Files) => file.section?.type === 2);
      const work = data?.filter((file: Files) => file.section?.type === 3);
      dispatch({
        type: cabinetConstants.GET_COMMON_FILES_SUCCESS,
        payload: common,
      });
      dispatch({
        type: cabinetConstants.GET_SPECIAL_FILES_SUCCESS,
        payload: special,
      });
      dispatch({
        type: cabinetConstants.GET_WORK_FILES_SUCCESS,
        payload: work,
      });
    })
    .catch((err) => {
      if (err.response?.status === 401) {
        dispatch({ type: cabinetConstants.GO_TO_LOGIN });
      }
      dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            autoHideDuration: 5000,
            key: Date.now(), //+ index
            variant: 'error',
          },
        }),
      );
      dispatch({ type: cabinetConstants.GET_COMMON_FILES_FAILURE });
    });
};

export const getContacts = () => (dispatch: any) => {
  dispatch({
    type: cabinetConstants.GET_TEST_FETCHING,
  });
  return apiUserContacts
    .getUsersContacts()
    .then((res: any) => {
      return dispatch({
        type: cabinetConstants.GET_CONTACTS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response?.status === 401) {
        dispatch({ type: cabinetConstants.GO_TO_LOGIN });
      }
      dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            autoHideDuration: 5000,
            key: Date.now(), //+ index
            variant: 'error',
          },
        }),
      );
      dispatch({ type: cabinetConstants.GET_TEST_FAILURE });
    });
};

/**
 * @description запрос статистики тестов
 */

type GetTestsSummary = {
  type: typeof cabinetConstants.GET_TESTS_SUMMARY_SUCCESS;
  payload: ITestsSummary;
};
export const getTestsSummary = () => async (dispatch: any) => {
  dispatch({ type: cabinetConstants.GET_TESTS_SUMMARY_FETCHING });
  try {
    const res = await apiCabinet.getTestsSummary();
    return dispatch({
      type: cabinetConstants.GET_TESTS_SUMMARY_SUCCESS,
      payload: res.data,
    });
  } catch (error: any) {
    if (error.response.status === 401) {
      dispatch({ type: cabinetConstants.GO_TO_LOGIN });
    }
    dispatch(
      enqueueSnackbar({
        message: error.message,
        options: {
          autoHideDuration: 4000,
          key: Date.now(), //+ index
          variant: 'error',
        },
      }),
    );
    dispatch({ type: cabinetConstants.GET_TESTS_SUMMARY_FAILURE });
  }
};
/**
 * @description запрос данных Employee
 */

type GetEmployeeData = {
  type: typeof cabinetConstants.GET_EMPLOYEE_DATA_SUCCESS;
  payload: IEmployeeData;
};
export const getEmployeeData = () => async (dispatch: any) => {
  dispatch({ type: cabinetConstants.GET_EMPLOYEE_DATA_FETCHING });
  try {
    const res = await apiCabinet.getEmployeeData();
    //console.log('action.getEmployeeData.res.data', res.data);
    return dispatch({
      type: cabinetConstants.GET_EMPLOYEE_DATA_SUCCESS,
      payload: res.data,
    });
  } catch (error: any) {
    if (error.response.status === 401) {
      dispatch({ type: cabinetConstants.GO_TO_LOGIN });
    }
    dispatch(
      enqueueSnackbar({
        message: error.message,
        options: {
          autoHideDuration: 4000,
          key: Date.now(), //+ index
          variant: 'error',
        },
      }),
    );
    dispatch({ type: cabinetConstants.GET_EMPLOYEE_DATA_FAILURE });
  }
};

/**
 * @description установка текущего таба календаря (0-неделя, 1-день)
 */
type SetCalendarTabValueType = {
  type: typeof cabinetConstants.SET_CALENDAR_TAB_VALUE;
  payload: number;
};
export const setCalendarTabValue = (value: number) => (dispatch: any) => {
  return dispatch({
    type: cabinetConstants.SET_CALENDAR_TAB_VALUE,
    payload: value,
  });
};

export type cabinetActions =
  | SetTabValue
  | SetNewsTabValue
  | GetPersonalNews
  | GetPersonalNewsFilter
  | GetNewNewsSuccess
  | GetUnreadedPersonalNews
  | ReadNewsSuccess
  | GetAllNewsSuccess
  | MergeNewsToAll
  | ReadAlert
  | GetAlerts
  | DeleteAlert
  | SetProfileSettings
  | GetEmployeeData
  | SwitchProfileSettingType
  | GetTestsSummary
  | SetCalendarTabValueType
  | SetScreenSize;
