import React, { Suspense } from 'react';
import { Switch, Router } from 'react-router-dom';
import { object } from 'prop-types';
import { LastLocationProvider } from 'react-router-last-location';
import routes from '../routes/routes';
import Loader from '../../rootComponents/Loader/Loader';
import { history } from '../store/configureStore';

const propTypes = {
  store: object.isRequired,
};

const AppRouter = ({ store }) => {
  return (
    <Router history={history}>
      <LastLocationProvider watchOnlyPathname>
        {/* Suspense & Loader - лоадер загрузки асинхронных роутов-чанков */}
        <Suspense fallback={<Loader />}>
          <Switch>{routes(store)}</Switch>
        </Suspense>
      </LastLocationProvider>
    </Router>
  );
};

AppRouter.propTypes = propTypes;

export default AppRouter;
