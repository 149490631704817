import React from 'react';
import { string, object, elementType } from 'prop-types';
import Layout from '@components/common/Layout/Layout';
import Notifier from '../../rootComponents/Notifier/Notifier';

const propTypes = {
  component: elementType.isRequired,
  formattedTitle: string.isRequired,
  className: string.isRequired,
  componentProps: object,
  direction: string,
};

const defaultProps = {
  componentProps: {},
  direction: 'row',
};

const RouteContent = ({
  component: Component,
  formattedTitle,
  className,
  componentProps,
  direction,
}) => {
  return (
    <>
      <Notifier />
      <Layout
        formattedTitle={formattedTitle}
        className={`page ${className}`}
        direction={direction}
      >
        <Component {...componentProps} />
      </Layout>
    </>
  );
};

RouteContent.propTypes = propTypes;
RouteContent.defaultProps = defaultProps;

export default RouteContent;
