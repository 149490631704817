import React from 'react';
import {
  Avatar,
  Button,
  Card,
  Divider,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Contact } from '../pages/WorkPage';
import NoAvatar from '../../images/no-avatar.png';
const PREFIX = process.env.REACT_APP_BACKEND_API_PREFIX;
// const HOST = process.env.REACT_APP_BACKEND_API; // adel
const STATIC = '/static';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
  },
  avatar: {
    marginRight: 10,
    width: 50,
    height: 50,
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  contacts: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
  },
  divider: {
    width: '100%',
    height: 1,
    margin: 10,
    backgroundColor: 'lightgrey',
  },
  button: {
    '&:hover': {
      color: 'white',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '10px 0',
    },
  },
  submit: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

interface Props {
  contact: Contact;
}

const ContactCard: React.FC<Props> = ({ contact }) => {
  const classes = useStyles();
  const intl = useIntl();
  const avatar = contact.photo
    ? PREFIX + STATIC + contact?.photo?.url
    : NoAvatar;

  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Card className={classes.card}>
      <Grid container spacing={1}>
        <Grid item className={classes.info} xs={12} sm={4} md={4} lg={4} xl={4}>
          <Avatar className={classes.avatar} src={avatar}>
            {contact?.firstName
              ? contact.firstName.substr(0, 2).toUpperCase()
              : ''}
          </Avatar>
          <div>
            <Typography variant='body2'>
              {contact.firstName + ' ' + contact.lastName}
            </Typography>
            {/* <Typography variant='caption'>{}</Typography> */}
          </div>
        </Grid>
        <Hidden xsDown>
          <Divider orientation='vertical' variant='middle' flexItem />
        </Hidden>
        <Hidden smUp>
          <div className={classes.divider} />
        </Hidden>

        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          className={classes.contacts}
        >
          <a href={`mailto:${contact.email}`}>
            <Typography
              style={{
                ...(contact.email.length > 32 && !xsDown
                  ? { fontSize: '.75rem' }
                  : { fontSize: '1rem' }),
              }}
            >
              {contact.email}
            </Typography>
          </a>
          <a href={`tel:${contact.phoneNumber}`}>
            <Typography color='primary'>{contact.phoneNumber}</Typography>
          </a>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          className={classes.submit}
        >
          <Button
            variant='contained'
            color='primary'
            className={classes.button}
            href={`mailto:${contact.email}`}
          >
            {intl.formatMessage({ id: 'component.contact.button.write' })}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export default React.memo(ContactCard);
