import React from 'react';
import Cookies from 'js-cookie';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import AppRouter from './router/AppRouter';
import IntlProvider from '@hoc/IntlProvider';
import ErrorBoundary from '@components/common/ErrorBoundary/ErrorBoundary';
import { ROOT_EL } from '@config/config';
import { store } from './store/configureStore';
import { getProfile } from '@actions/volunteer';
import { getTranslations } from '@actions/lang';
import Loader from '../rootComponents/Loader/Loader';
import SnackbarProvider from '../rootComponents/SnackbarProvider/SnackbarProvider';
import '../index.scss';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { SITE_KEY } from './constants/recaptcha';

const rootEl = document.getElementById(ROOT_EL);

const { lang } = store.getState();
Cookies.set('LANG', lang.locale);
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000000',
      light: '#8B00FF',
    },
    secondary: {
      main: '#787878',
      light: '#60A795',
    },
  },
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {
        maxWidth: 'none',
      },
    },
    MuiButton: {
      contained: {
        border: '3px solid black',
        borderRadius: 0,
        backgroundColor: 'white',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: '#A9A9A9',
        },
      },
    },
    MuiFab: {
      root: {
        width: 56,
        minWidth: 56,
        height: 56,
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: 'none',
        color: 'black',
        border: '3px solid black',
        fontSize: 26,
        '&:hover': {
          background: '#A9A9A9',
        },
        '&:focus': {
          outline: 'none',
        },
      },
    },

    common: {
      title: '#3F3B5D',
    },
  },
  typography: {
    body2: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    allVariants: {
      fontFamily: 'Roboto, sans-serif',
    },
    fontFamily: 'Roboto, sans-serif',
  },
});

const App = () => {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <IntlProvider>
            <SnackbarProvider>
              <GoogleReCaptchaProvider
                reCaptchaKey={SITE_KEY}
                language={lang.locale}
              >
                <AppRouter store={store} />
              </GoogleReCaptchaProvider>
            </SnackbarProvider>
          </IntlProvider>
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  );
};

render(<Loader />, rootEl);
/**
 * @description грузим переводы
 * потом профайл и после этого рендерим приложение
 */
const renderApp = async () => {
  await store.dispatch(getTranslations());
  store.dispatch(getProfile()).finally(() => render(<App />, rootEl));
};

export { renderApp };
