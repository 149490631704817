import * as scheduleConstants from '../constants/scheduleConstants';

const initialState: ScheduleState = {
  interviewersSlots: [],
  selectedSlot: {},
  workShifts: [],
  interviewStatus: '',
  interviewDateInfo: undefined,
  loading: false,
};

export interface InterviewDateInfo {
  id: number;
  startDate: string;
  endDate: string;
}

export type InterviewStatus = 'success' | 'error' | 'limit' | '';

export type ScheduleActions = {
  type: string;
  payload?: any;
};

export interface ScheduleState {
  interviewersSlots: any[];
  selectedSlot: any;
  workShifts: any[];
  interviewStatus: InterviewStatus;
  interviewDateInfo?: InterviewDateInfo;
  loading: boolean;
}

export default (
  state: ScheduleState = initialState,
  actions: ScheduleActions,
): ScheduleState => {
  switch (actions.type) {
    case scheduleConstants.GET_INTERVIEW_SLOTS:
      return {
        ...state,
        interviewersSlots: actions.payload,
      };
    case scheduleConstants.SET_INTERVIEW_STATUS:
      return {
        ...state,
        interviewStatus: actions.payload,
      };
    case scheduleConstants.GET_WORK_SHIFTS:
      return {
        ...state,
        workShifts: actions.payload,
      };
    case scheduleConstants.SELECT_INTERVIEW_SLOT:
      return {
        ...state,
        selectedSlot: actions.payload,
      };
    case scheduleConstants.DELETE_INTERVIEW_SLOT:
      return {
        ...state,
        selectedSlot: actions.payload,
      };
    case scheduleConstants.TOGGLE_INTERVIEW_LOADING:
      return {
        ...state,
        loading: actions.payload,
      };
    case scheduleConstants.SET_INTERVIEW_DATE_INFO:
      return {
        ...state,
        interviewDateInfo: actions.payload,
        interviewStatus: 'success',
      };
    case scheduleConstants.REMOVE_INTERVIEW_DATE_INFO:
      return {
        ...state,
        interviewDateInfo: undefined,
      };
    default:
      return {
        ...state,
      };
  }
};
