import { volunteerConstants } from '@constants/volunteer';
import { cabinetConstants } from '@constants/cabinet';
import fakeVolunteer from '@fake-data/volunteer';

const initialState = {
  loading: false,
  error: '',
  profile: {
    ...fakeVolunteer.profile,
  },
  isAuthenticated: false,
  resetPassToken: '',
  isNewPassTokenValid: false,
  loginType: 1, //for loginChangeReduxForm initial value
  thankYou: false,
  verifCodeModalOpened: false,
  changeLoginModalOpened: false,
  changePassModalOpened: false,
  refuseModalOpened: false,
  feedbackModalOpened: false,
  isRefused: false,
};

export default (state = initialState, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case volunteerConstants.LOGIN_SUCCESS:
    case volunteerConstants.LOGIN_SOCIAL_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        error: '',
      };
    case volunteerConstants.GET_PROFILE_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        profile: {
          ...state.profile,
          ...payload,
        },
        error: '',
      };
    case volunteerConstants.RESET_PASS_SUCCESS:
    case volunteerConstants.CHECK_NEW_PASS_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        profile: initialState.profile,
        error: '',
        resetPassToken: action.payload,
        isNewPassTokenValid: true,
      };

    case volunteerConstants.RESET_PASS_FETCHING:
    case volunteerConstants.SET_NEW_PASS_FETCHING:
    case volunteerConstants.CHECK_NEW_PASS_TOKEN_FETCHING:
    case volunteerConstants.LOGIN_FETCHING:
    case volunteerConstants.LOGIN_SOCIAL_FETCHING:
    case volunteerConstants.REGISTER_FETCHING:
    case volunteerConstants.LOGOUT_FETCHING:
    case volunteerConstants.GET_PROFILE_FETCHING:
    case volunteerConstants.GENERATE_CODE_FETCHING:
    case volunteerConstants.VERIFY_CODE_FETCHING:
    case volunteerConstants.CHANGE_PASS_FETCHING:
    case volunteerConstants.REFUSE_PARTICIPATE_FETCHING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case volunteerConstants.GENERATE_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        verifCodeModalOpened: true,
      };
    case volunteerConstants.VERIFY_CODE_SUCCESS:
    case volunteerConstants.CHANGE_PASS_SUCCESS:
    case volunteerConstants.CHANGE_LOGIN_SUCCESS:
    case volunteerConstants.CANCEL_LOGIN_CHANGING:
    case volunteerConstants.CHANGE_LOGIN_FAILURE:
      return {
        ...state,
        error: '',
        verifCodeModalOpened: false,
        changePassModalOpened: false,
      };
    case volunteerConstants.CHANGE_LOGIN_MODAL_OPEN:
      return {
        ...state,
        error: '',
        changeLoginModalOpened: true,
      };
    case volunteerConstants.CHANGE_LOGIN_MODAL_CLOSE:
      return {
        ...state,
        error: '',
        changeLoginModalOpened: false,
      };
    case volunteerConstants.CHANGE_PASS_MODAL_OPEN:
      return {
        ...state,
        error: '',
        changePassModalOpened: true,
      };
    case volunteerConstants.CHANGE_PASS_MODAL_CLOSE:
      return {
        ...state,
        error: '',
        changePassModalOpened: false,
      };
    case volunteerConstants.REFUSE_MODAL_OPEN:
      return {
        ...state,
        error: '',
        refuseModalOpened: true,
      };
    case volunteerConstants.REFUSE_MODAL_CLOSE:
      return {
        ...state,
        error: '',
        refuseModalOpened: false,
      };
    case volunteerConstants.FEEDBACK_MODAL_OPEN:
      return {
        ...state,
        error: '',
        feedbackModalOpened: true,
      };
    case volunteerConstants.FEEDBACK_MODAL_CLOSE:
      return {
        ...state,
        error: '',
        feedbackModalOpened: false,
      };
    case volunteerConstants.SET_NEW_PASS_SUCCESS:
    case volunteerConstants.REGISTER_SUCCESS:
    case volunteerConstants.LOGOUT_SUCCESS:
    case volunteerConstants.CANCEL_PASS_RECOVERY:
    case cabinetConstants.GO_TO_LOGIN:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        profile: initialState.profile,
        //isNewPassTokenValid: false,
        resetPassToken: '',
        error: '',
      };

    case volunteerConstants.SET_LOGIN_TYPE:
      return {
        ...state,
        loginType: action.payload,
      };
    case volunteerConstants.REGISTER_THANKYOU:
      return {
        ...state,
        thankYou: true,
      };
    case volunteerConstants.REGISTER_THANKYOU_CLOSE:
      return {
        ...state,
        thankYou: false,
      };
    case volunteerConstants.REFUSE_PARTICIPATE_SUCCESS:
      return {
        ...state,
        isRefused: payload,
        loading: false,
      };
    case volunteerConstants.CHECK_NEW_PASS_TOKEN_FAILURE:
      return {
        ...state,
        isNewPassTokenValid: false,
      };
    case volunteerConstants.RESET_PASS_FAILURE:
    case volunteerConstants.SET_NEW_PASS_FAILURE:
    case volunteerConstants.LOGIN_FAILURE:
    case volunteerConstants.LOGIN_SOCIAL_FAILURE:
    case volunteerConstants.REGISTER_FAILURE:
    case volunteerConstants.LOGOUT_FAILURE:
    case volunteerConstants.GET_PROFILE_FAILURE:
    case volunteerConstants.GENERATE_CODE_FAILURE:
    case volunteerConstants.VERIFY_CODE_FAILURE:
    case volunteerConstants.CHANGE_PASS_FAILURE:
    case volunteerConstants.REFUSE_PARTICIPATE_FAILURE:
      // TODO: это я не использую. но хорошо бы было. не было времени и мыслей
      // куда пристроить возможные ошибки с бэка для рендера
      return {
        ...state,
        loading: false,
        resetPassToken: '',
        error:
          (payload && payload.error) ||
          [
            'REGISTER_FAILURE',
            'LOGOUT_FAILURE',
            'LOGIN_FAILURE',
            'GET_PROFILE_FAILURE',
            'RESET_PASS_FAILURE',
            'SET_NEW_PASS_FAILURE',
            'CHECK_NEW_PASS_TOKEN_FAILURE',
            'CHECK_NEWS_FAILURE',
            'GENERATE_CODE_FAILURE',
            'VERIFY_CODE_FAILURE',
            'CHANGE_PASS_FAILURE',
            'CHANGE_LOGIN_FAILURE',
          ].join(' / '),
      };
    default:
      return state;
  }
};
