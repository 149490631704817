import { AllNewsParamsType } from './../api/routes/volunteer';
import {
  IAlert,
  NewsItemI,
} from './../../volunteers/components/News/newsTypes';
import { cabinetActions, ScreenSizeType } from './../actions/cabinet';
import { cabinetConstants } from './../constants/cabinet';
import { volunteerConstants } from './../constants/volunteer';
// import { Test } from '../../volunteers/pages/TestingPage';

export interface ITestsSummary {
  tests: number;
  points: number;
}

export interface IEmployeeData {
  shouldSendNotifications: boolean;
}
export interface ICabinetState {
  loading: boolean;
  loadingAlerts: boolean;
  loadingTests: boolean;
  error: string;
  currentTabValue: string;
  currentNewsTabValue: number;
  allNews: NewsItemI[];
  unreadedNews: NewsItemI[];
  currentNewsList: NewsItemI[];
  personalNews: NewsItemI[];
  unreadedPersonalNews: NewsItemI[];
  filterForPersonalNews: AllNewsParamsType;
  alerts: IAlert[];
  commonFiles: any;
  specialFiles: any;
  workFiles: any;
  tests: any; //Test[];
  contacts: any;
  testsSummary: ITestsSummary;
  employeeData: IEmployeeData;
  employeeDataFetched: boolean;
  unreadedPersonalNewsFetched: boolean;
  screenSize: ScreenSizeType;
  currentCalendarTab: number;
}

const initialState: ICabinetState = {
  loading: false,
  loadingAlerts: false,
  loadingTests: false,
  error: '',
  currentTabValue: 'news',
  currentNewsTabValue: 1,
  unreadedNews: [],
  unreadedPersonalNews: [],
  allNews: [],
  personalNews: [],
  currentNewsList: [],
  filterForPersonalNews: {
    team: 0,
    area: 0,
    block: 0,
    lang: 'ru',
  },
  alerts: [],
  commonFiles: [],
  specialFiles: [],
  workFiles: [],
  tests: [],
  contacts: [],
  testsSummary: {
    tests: 0,
    points: 0,
  },
  employeeData: {
    shouldSendNotifications: false,
  },
  employeeDataFetched: false,
  unreadedPersonalNewsFetched: false,
  screenSize: { height: 0, width: 0 },
  currentCalendarTab: 0,
};

export default (
  state = initialState,
  action: cabinetActions,
): ICabinetState => {
  const { type, payload } = action;
  switch (type) {
    case cabinetConstants.SET_TAB_VALUE:
      return {
        ...state,
        currentTabValue: payload as string,
      };
    case cabinetConstants.SET_SCREEN_SIZE:
      return {
        ...state,
        screenSize: payload as ScreenSizeType,
      };
    case cabinetConstants.SET_NEWS_TAB_VALUE:
      return {
        ...state,
        currentNewsTabValue: payload as number,
      };
    case cabinetConstants.SET_CALENDAR_TAB_VALUE:
      return {
        ...state,
        currentCalendarTab: payload as number,
      };
    case cabinetConstants.SWITCH_PROFILE_SETTING:
      return {
        ...state,
        employeeData: {
          ...state.employeeData,
          shouldSendNotifications: payload as boolean,
        },
      };
    case cabinetConstants.GET_PERSONAL_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        personalNews: [...(payload as NewsItemI[])],
      };
    case cabinetConstants.GET_PERSONAL_NEWS_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        filterForPersonalNews: {
          ...state.filterForPersonalNews,
          ...(payload as AllNewsParamsType),
        },
      };
    case cabinetConstants.GET_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        unreadedNews: [...(payload as NewsItemI[])],
      };
    case cabinetConstants.GET_ALL_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        allNews: [...(payload as NewsItemI[])],
      };
    case cabinetConstants.GET_UNREADED_PERSONAL_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        unreadedPersonalNews: [...(payload as NewsItemI[])],
        unreadedPersonalNewsFetched: true,
      };
    case cabinetConstants.READ_NEWS_SUCCESS:
      return {
        ...state,
        unreadedNews: state.unreadedNews.filter(
          (newsItem) => newsItem.id !== payload,
        ),
        unreadedPersonalNews: state.unreadedPersonalNews.filter(
          (newsItem) => newsItem.id !== payload,
        ),
        loading: false,
        error: '',
      };
    case cabinetConstants.READ_ALERT_SUCCESS: {
      // const currentAlert = alerts.find((alert) => alert.id === payload);
      const alertsCopy = state.alerts.slice();
      const index = alertsCopy.findIndex((a) => a.id === payload);
      const readedAlert = alertsCopy[index];
      readedAlert.readed = true;
      const arrStart = alertsCopy.slice(0, index);
      const arrEnd = alertsCopy.slice(index + 1);

      return {
        ...state,
        alerts: [...arrStart, readedAlert, ...arrEnd],
        loadingAlerts: false,
        error: '',
      };
    }
    case cabinetConstants.DELETE_ALERT_SUCCESS:
      return {
        ...state,
        alerts: state.alerts.filter((alert) => alert.id !== payload),
        loading: false,
        error: '',
      };
    case cabinetConstants.GET_COMMON_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        commonFiles: payload,
      };
    case cabinetConstants.GET_SPECIAL_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        specialFiles: payload,
      };
    case cabinetConstants.GET_WORK_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        workFiles: payload,
      };
    case cabinetConstants.GET_TEST_SUCCESS: {
      return {
        ...state,
        loadingTests: false,
        error: '',
        tests: Array.isArray(payload) ? [...payload] : payload,
      };
    }
    case cabinetConstants.GET_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        contacts: payload,
      };
    case cabinetConstants.GET_EMPLOYEE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        employeeData: { ...state.employeeData, ...(payload as IEmployeeData) },
        employeeDataFetched: true,
      };
    case cabinetConstants.GET_TESTS_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        testsSummary: { ...state.testsSummary, ...(payload as ITestsSummary) },
      };
    case volunteerConstants.LOGOUT_SUCCESS:
    case cabinetConstants.GO_TO_LOGIN:
      return {
        ...state,
        ...(initialState as ICabinetState),
      };

    // case cabinetConstants.READ_NEWS_FETCHING:
    case cabinetConstants.GET_ALERTS_FETCHING:
    case cabinetConstants.DELETE_ALERT_FETCHING:
      return {
        ...state,
        loadingAlerts: true,
        error: '',
      };
    case cabinetConstants.GET_NEWS_FETCHING:
    case cabinetConstants.GET_ALL_NEWS_FETCHING:
    case cabinetConstants.GET_COMMON_FILES_FETCHING:
    case cabinetConstants.GET_SPECIAL_FILES_FETCHING:
    case cabinetConstants.GET_TESTS_SUMMARY_FETCHING:
    case cabinetConstants.GET_EMPLOYEE_DATA_FETCHING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case cabinetConstants.GET_TEST_FETCHING:
      return {
        ...state,
        loadingTests: true,
        error: '',
      };

    case cabinetConstants.MERGE_NEWS_TO_ALL:
      return {
        ...state,
        allNews: [...state.allNews, ...state.personalNews],
      };
    case cabinetConstants.GET_ALERTS_SUCCESS: {
      return {
        ...state,
        loadingAlerts: false,
        alerts: payload as IAlert[],
      };
    }
    case cabinetConstants.GET_ALERTS_FAILURE:
      return {
        ...state,
        loadingAlerts: false,
      };

    case cabinetConstants.GET_NEWS_FAILURE:
    case cabinetConstants.READ_NEWS_FAILURE:
    case cabinetConstants.GET_ALL_NEWS_FAILURE:
    case cabinetConstants.READ_ALERT_FAILURE:
    case cabinetConstants.GET_COMMON_FILES_FAILURE:
    case cabinetConstants.GET_SPECIAL_FILES_FAILURE:
    case cabinetConstants.GET_TESTS_SUMMARY_FAILURE:
    case cabinetConstants.GET_EMPLOYEE_DATA_FAILURE:
      // TODO:
      //  пристроить возможные ошибки с бэка для рендера
      return {
        ...state,
        loading: false,
      };
    case cabinetConstants.GET_TEST_FAILURE:
      return {
        ...state,
        loadingTests: false,
      };
    default:
      return state;
  }
};
