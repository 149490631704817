import React, { ReactElement } from 'react';
import {
  Backdrop as CustomBackdrop,
  IconButton as IconButtonComp,
  makeStyles,
  Modal,
  Paper,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  open: boolean;
  close?: () => void;
  title: string | ReactElement;
  message: string | ReactElement;
  message2?: string | ReactElement | React.ReactNode;
  variant?: string;
  url?: string;
  urlText?: string;
}

const IconButton = withStyles({
  root: {
    position: 'absolute',
    right: 20,
    top: 20,
    fontSize: 25,
  },
})(IconButtonComp);

const Backdrop = withStyles({
  root: {
    filter: 'blur(10px)',
    backgroundColor: 'rgb(0%, 0%, 0%, 0.5)',
  },
})(CustomBackdrop);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'relative',
    minWidth: '50%',
    maxWidth: '80%',
    backgroundColor: 'white',
    minHeight: 300,
    maxHeight: '90vh',
    opacity: 1,
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 30,
    textAlign: 'center',
  },
  title: {
    textTransform: 'uppercase',
    paddingBottom: '2rem',
    marginTop: '2rem',
  },
  message: {
    paddingBottom: '2rem',
  },
  message2: {
    //paddingBottom: '2rem',
    fontSize: 16,
    color: '#a21919',
    overflow: 'auto',
    paddingBottom: 5,
    whiteSpace: 'break-spaces',
  },
}));

const CenterNotification: React.FC<Props> = ({
  open,
  close,
  title,
  message,
  message2,
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={close}
      className={classes.root}
      BackdropComponent={Backdrop}
    >
      <Paper className={classes.paper}>
        <IconButton onClick={close}>
          <CloseIcon />
        </IconButton>
        <Typography variant='h3' className={classes.title}>
          {title}
        </Typography>
        <Typography variant='h5' className={classes.message}>
          {message}
        </Typography>
        {message2 && (
          <Typography
            variant='subtitle1'
            component='div'
            className={classes.message2}
          >
            {message2}
          </Typography>
        )}
      </Paper>
    </Modal>
  );
};

export default React.memo(CenterNotification);
