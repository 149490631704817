export const resumeConstants = {
  RESUME_GET_FETCHING: 'RESUME_GET_FETCHING',
  RESUME_GET_SUCCESS: 'RESUME_GET_SUCCESS',
  RESUME_GET_FAILURE: 'RESUME_GET_FAILURE',

  RESUME_GET_AFTER_LOGIN_FETCHING: 'RESUME_GET_AFTER_LOGIN_FETCHING',
  RESUME_GET_AFTER_LOGIN_SUCCESS: 'RESUME_GET_AFTER_LOGIN_SUCCESS',
  RESUME_GET_AFTER_LOGIN_FAILURE: 'RESUME_GET_AFTER_LOGIN_FAILURE',

  RESUME_EDIT_FETCHING: 'RESUME_EDIT_FETCHING',
  RESUME_EDIT_SUCCESS: 'RESUME_EDIT_SUCCESS',
  RESUME_EDIT_FAILURE: 'RESUME_EDIT_FAILURE',

  RESUME_SAVE_FETCHING: 'RESUME_SAVE_FETCHING',
  RESUME_SAVE_SUCCESS: 'RESUME_SAVE_SUCCESS',
  RESUME_SAVE_FAILURE: 'RESUME_SAVE_FAILURE',

  RESUME_PUBLISH_FETCHING: 'RESUME_PUBLISH_FETCHING',
  RESUME_PUBLISH_SUCCESS: 'RESUME_PUBLISH_SUCCESS',
  RESUME_PUBLISH_FAILURE: 'RESUME_PUBLISH_FAILURE',

  READY_MODAL_OPEN: 'READY_MODAL_OPEN',
  READY_MODAL_CLOSE: 'READY_MODAL_CLOSE',

  FAIL_MODAL_OPEN: 'FAIL_MODAL_OPEN',
  FAIL_MODAL_CLOSE: 'FAIL_MODAL_CLOSE',

  RESUME_SET_PUBLISH_ERROR: 'RESUME_SET_PUBLISH_ERROR',
};

export const RESUME_ACTIVE_STATUS_LIST = [1, 2];
