import { history } from '../../js/store/configureStore';
import { call, put, takeLatest } from 'redux-saga/effects';
import apiResume from '../../js/api/routes/resume';
import {
  RESUME_ACTIVE_STATUS_LIST,
  resumeConstants,
} from '../../js/constants/resume';

function* worker() {
  yield put({ type: resumeConstants.RESUME_GET_AFTER_LOGIN_FETCHING });
  try {
    const data = yield call(apiResume.get);
    yield put({
      type: resumeConstants.RESUME_GET_AFTER_LOGIN_SUCCESS,
      payload: data,
    });

    if (!RESUME_ACTIVE_STATUS_LIST.includes(data.application?.[0].status.id)) {
      history.push('/cabinet/news');
    } else {
      history.push('/summary');
    }
  } catch (error) {
    yield put({ type: resumeConstants.RESUME_GET_AFTER_LOGIN_FAILURE });
    history.push('/cabinet/news');
  }
}

export function* watcher(): any {
  yield takeLatest('LOGIN_SUCCESS', worker);
  yield takeLatest('LOGIN_SOCIAL_SUCCESS', worker);
}
