import axios, { AxiosRequestConfig } from 'axios';
import { getAuthToken, getCookieLang } from '../../utils/utils';
import { handleResponseSuccess } from '../helpers/handle-response';

export const req = axios.create();

req.defaults.baseURL = process.env.REACT_APP_BACKEND_API_PREFIX; // хост или пустота
req.defaults.withCredentials = true;
req.defaults.timeout = 999999;

/**
 * @description в каждый запрос записываются куки токена авторизации
 * и выбранного языка
 */
req.interceptors.request.use((request) => {
  request.headers['Authorization'] = getAuthToken();
  request.headers['LANG'] = getCookieLang();

  return request;
});

req.interceptors.response.use(
  (response): Promise<any> => {
    handleResponseSuccess(response);
    return Promise.resolve({
      data: response.data,
      status: response.status,
      statusText: response.statusText,
      error: response.data.error || '',
    });
  },
  (error) => {
    //handleResponseErrors(error);
    return Promise.reject(error);
  },
);

export type ReadAlertParamType = {
  id: number;
};

const cabinetRoutes = {
  readAlert(data: ReadAlertParamType): Promise<any> {
    const config: AxiosRequestConfig = {
      url: '/alerts',
      method: 'put',
      data,
    };
    return req.request(config);
  },

  deleteAlert(id: ReadAlertParamType): Promise<any> {
    const config: AxiosRequestConfig = {
      url: '/alerts',
      method: 'delete',
      data: id,
    };
    return req.request(config);
  },

  getTestsSummary(): Promise<any> {
    const config: AxiosRequestConfig = {
      url: '/training/user/stats',
      method: 'get',
      data: {},
    };
    return req.request(config);
  },

  getEmployeeData(): Promise<any> {
    const config: AxiosRequestConfig = {
      url: '/Employee',
      method: 'get',
      data: {},
    };
    return req.request(config);
  },
};

export default cabinetRoutes;
