import { Component } from 'react';
import { node } from 'prop-types';
import { isNil } from '@utils/utils';
// TODO: я бы переписал на функциональный компонент
/**
 * @description то что оборачивается в этот компонент становится
 * способным отлавливать JS ошибки и не рушить приложение. сейчас им обернуто все приложение
 * но можно оборачивать отдельные компоненты  допустим, чтобы если на каком-то компоненте в продакшне
 * JS ошибки - люди бы могли дальше пользоваться приложением - а тот ошибочный компонент
 * просто самовыпиливается из рендера
 *
 * но это не донастроено
 */
class ErrorBoundary extends Component {
  static propTypes = {
    fallback: node,
    children: node.isRequired,
  };

  state = {
    hasError: false,
  };
  /**
   * @description перехватчик - херачит ошибки в консоль
   */
  componentDidCatch(error = '', info = {}) {
    this.setState(
      () => ({ hasError: true }),
      () => {
        const report = {
          url: window.location.href,
          message: error.toString(),
          referrer: document.referrer,
          stack: info.componentStack,
        };

        console.error(report);
      },
    );
  }
  /**
   * @description можно в пропсы передавать заглушки которые будут показыватся вместо
   * упавшего компонента
   */
  render() {
    if (this.state.hasError) {
      if (!isNil(this.props.fallback)) {
        return this.props.fallback;
      }
      return null;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
