import {
  Button,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import EventIcon from '@material-ui/icons/Event';
import {
  InterviewDateInfo,
  InterviewStatus,
} from '../../../js/reducers/schedule';
import Modal from '../../../rootComponents/Modal/Modal';
import { date } from 'joi';
import moment from 'moment';

interface Props {
  status?: InterviewStatus;
  open: boolean;
  onClose: () => void;
  info?: InterviewDateInfo;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    margin: '10px 0',
  },
  timeBlock: {
    margin: '20px 5px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    padding: 30,
    position: 'relative',
  },
  icon: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 5,
    right: 10,
  },
  button: {
    marginTop: 10,
  },
}));

const ResponseModal: FC<Props> = ({ status, open, onClose, info }) => {
  const { formatMessage: fm } = useIntl();
  const classes = useStyles();
  const { locale } = useSelector((state: any) => state.lang);

  const googleCalendarOptions = {
    text: fm({ id: 'calendar.google.title' }),
    dates: [
      moment(info?.startDate).format('YYYYMMDD[T]HHmmss'),
      moment(info?.endDate).format('YYYYMMDD[T]HHmmss'),
    ].join('/'),
    action: 'TEMPLATE',
    details: fm({ id: 'calendar.google.details' }),
    location: '',
    sprop: 'name:',
  };

  const params = new URLSearchParams(googleCalendarOptions);
  const calendarUrl = 'https://www.google.com/calendar/render';
  const serviceMail = 'volunteers@ossp.spb.ru';

  const GOOGLE_HREF = [calendarUrl, params.toString()].join('?');

  const timeBlock = useMemo(() => {
    if (info && status === 'success') {
      return (
        <div className={classes.timeBlock}>
          <IconButton
            target='_blank'
            rel='noreferrer'
            href={GOOGLE_HREF}
            className={classes.icon}
            size='small'
          >
            <Tooltip title={fm({ id: `calendar.google.tooltip.title` })}>
              <EventIcon />
            </Tooltip>
          </IconButton>
          <Typography>
            {[
              fm({ id: `calendar.dialog.interview.status.success.date` }),
              new Date(info.startDate).toLocaleString(locale, {
                day: 'numeric',
                month: 'long',
              }),
            ].join(': ')}
          </Typography>
          <Typography>
            {[
              fm({ id: `calendar.dialog.interview.status.success.time.start` }),
              new Date(info.startDate).toLocaleString(locale, {
                hour: 'numeric',
                minute: 'numeric',
              }),
            ].join(': ')}
          </Typography>
          <Typography>
            {[
              fm({ id: `calendar.dialog.interview.status.success.time.end` }),
              new Date(info.endDate).toLocaleString(locale, {
                hour: 'numeric',
                minute: 'numeric',
              }),
            ].join(': ')}
          </Typography>
        </div>
      );
    } else return;
  }, [info, locale]);

  const renderMessage = useMemo(() => {
    return (
      <div className={classes.container}>
        <Typography variant='h5' className={classes.title}>
          {status
            ? fm({ id: `calendar.dialog.interview.status.title.${[status]}` })
            : ''}
        </Typography>
        <Typography>
          {status
            ? fm({ id: `calendar.dialog.interview.status.text.${[status]}` })
            : ''}
        </Typography>
        <Typography>
          {status === 'limit'
            ? fm({ id: `calendar.dialog.interview.status.error.manager` })
            : ''}
        </Typography>
        {status === 'limit' && (
          <Typography component='a' href={`mailto:${serviceMail}`}>
            {serviceMail}
          </Typography>
        )}
        {timeBlock}
        {status === 'success' && (
          <Button
            variant='contained'
            color='primary'
            className={classes.button}
            onClick={onClose}
          >
            {fm({ id: `general.confirm` })}
          </Button>
        )}
      </div>
    );
  }, [status, classes]);

  return (
    <Modal open={open} onClose={onClose}>
      {renderMessage}
    </Modal>
  );
};

export default memo(ResponseModal);
