import React from 'react';
import { SnackbarProvider as Provider } from 'notistack';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    color: '#999',
    border: '#999 1px solid',
    '&:hover': { color: '#fff', border: '#fff 1px solid' },
  },
  success: { backgroundColor: '#5fc771 !important' },
  error: { backgroundColor: '#ff6e6e !important' },
  warning: { backgroundColor: '#ffb700 !important' },
  info: { backgroundColor: '#1eb3b0 !important' },
});
// add action to all snackbars
const notistackRef = React.createRef();
// const onClickDismiss = (key) => () => {
//   notistackRef.current.closeSnackbar(key);
// };

// TODO: переписать на TS
const SnackbarProvider = (props) => {
  //debugger; //eslint-disable-line

  const classes = useStyles();
  return (
    <Provider
      {...props}
      ref={notistackRef}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
      // action={(key) => (
      //   <Button
      //     onClick={onClickDismiss(key)}
      //     variant='outlined'
      //     size='small'
      //     classes={{ outlinedSizeSmall: classes.root }}
      //   >
      //     <FMessage id={'snackbar.button.title'} />
      //   </Button>
      // )}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      maxSnack={3}
      dense={true}
      preventDuplicate
      hideIconVariant
      // content={(key, message) => <SnackMessage id={key} message={message} />}
    />
  );
};

export default SnackbarProvider;
