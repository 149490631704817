import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { volunteerConstants } from '@constants/volunteer';
import volunteer from './volunteer';
import lang from './lang';
import resume from './resume';
import utils from './utils';
import lists from './lists';
import cabinet from './cabinetReducer';
import notifications from './notifications';
import test from './test';
import schedule from './schedule';

export default (routerReducer) =>
  combineReducers({
    router: routerReducer,
    lang,
    volunteer,
    resume,
    utils,
    lists,
    cabinet,
    test,
    schedule,
    // это общий редьюсер для всех инициализированных форм
    notifications,
    form: reduxFormReducer.plugin({
      resume: (state, action) => {
        // <------ 'account' is name of form given to reduxForm()
        switch (action.type) {
          case volunteerConstants.LOGOUT_SUCCESS:
            return undefined; // <--- blow away form data
          default:
            return state;
        }
      },
    }),
  });
