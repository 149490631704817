import { Tabs, Tab, Box, makeStyles, Theme, Hidden } from '@material-ui/core';
import React, { useCallback } from 'react';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import IconButton from '@material-ui/core/IconButton';
// import clsx from 'clsx';
import { navigate, views } from './utils/constants';

import FMessage from '../../../rootComponents/FMessage/FMessage';
import { useDispatch, useSelector } from 'react-redux';
import { setCalendarTabValue } from '../../../js/actions/cabinet';
import { IRootState } from '../../pages/Cabinet';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '0 5px',
  },
  tabs: {
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('xs')]: {
      // marginTop: 10
    },
  },
  dates: {
    marginLeft: 4,
    paddingTop: 8,
    paddingBottom: 8,
    [theme.breakpoints.down('xs')]: {
      margin: '0 auto',
      paddingTop: 16,
      paddingBottom: 0,
    },
  },
  box: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
    fontSize: 16,
  },
}));

const Toolbar = (props: any) => {
  const classes = useStyles();
  const {
    //localizer: {messages},
    label,
  } = props;

  const value = useSelector(
    (state: IRootState) => state.cabinet.currentCalendarTab,
  );

  const dispatch = useDispatch();

  const goto = (action: string) => {
    // console.log('navigate', 'action: ', action);
    props.onNavigate(action);
  };

  const handleChangeValue = useCallback(
    (
      _e,
      //: ChangeEvent<unknown>
      newValue,
      //: number
    ) => {
      props.onView(newValue === 0 ? views.WEEK : views.DAY);
      dispatch(setCalendarTabValue(newValue));
    },
    [dispatch],
  );

  return (
    <Box className={classes.box} display='flex' justifyContent='flex-start'>
      <Tabs
        value={value}
        onChange={handleChangeValue}
        indicatorColor='primary'
        textColor='primary'
        classes={{ flexContainer: classes.tabs }}
      >
        <Tab
          classes={{
            root: classes.root,
          }}
          label={FMessage({ id: 'page.work.schedule.week' })}
          value={0}
        />
        <Tab
          classes={{
            root: classes.root,
          }}
          label={FMessage({ id: 'page.work.schedule.day' })}
          value={1}
        />
      </Tabs>
      <Box className={classes.dates}>
        <IconButton
          onClick={() => goto(navigate.PREVIOUS)}
          style={{ padding: 0 }}
        >
          <NavigateBeforeIcon />
        </IconButton>
        <Hidden smUp>
          <span className='rbc-toolbar-label'>{label}</span>
        </Hidden>
        <IconButton
          onClick={() => goto(navigate.NEXT)}
          style={{ padding: 0, marginLeft: 0 }}
        >
          <NavigateNextIcon />
        </IconButton>
        <Hidden xsDown>
          <span>{label}</span>
        </Hidden>
      </Box>
    </Box>
  );
};

export default Toolbar;
