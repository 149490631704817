import { volunteerConstants } from '../constants/volunteer';
import { setAuthToken } from '@utils/utils';

/**
 * @description после успешного логина устанавливаем пришедший токен и
 * идем на /summary
 */
const loginMiddleware = (_store) => (next) => async (action) => {
  const result = next(action);
  const { LOGIN_SUCCESS, LOGIN_SOCIAL_SUCCESS } = volunteerConstants;

  if ([LOGIN_SUCCESS, LOGIN_SOCIAL_SUCCESS].includes(action.type)) {
    const { access_token, expiration_access_time } = action.payload;
    setAuthToken(access_token, {
      expired: expiration_access_time,
    });
  }

  return result;
};

export default loginMiddleware;
