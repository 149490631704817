import Cookies from 'js-cookie';
import { languageConstants } from '../constants/lang';
import apiTranslations from '../api/routes/translations';

// TODO: переделать на TS
// type ThunkActionType = ThunkAction<void, any, unknown, Action<string>>;

// type GetTranslationsAction = {
//   type: typeof languageConstants.GET_TRANSLATIONS_SUCCESS;
//   payload: {
//     data: I18n;
//     locale: string;
//   };
// };

// type ChangeLangAction = {
//   type: typeof languageConstants.SET_LOCALE;
//   payload: string;
// };

// type SetLangAction = {
//   type: typeof languageConstants.SET_LOCALE;
//   payload: string;
// };

/**
 * @description запрос дергает i18n со всеми ключами переводов для языка
 * язык я не передаю, т.к. он в куках есть
 */
export const getTranslations = () => (dispatch) => {
  dispatch({ type: languageConstants.GET_TRANSLATIONS_FETCHING });
  return apiTranslations
    .get()
    .then((data) => {
      return dispatch({
        type: languageConstants.GET_TRANSLATIONS_SUCCESS,
        payload: {
          data,
          locale: Cookies.get('LANG'),
        },
      });
    })
    .catch(() =>
      dispatch({ type: languageConstants.GET_TRANSLATIONS_FAILURE }),
    );
};

export const changeLang = (locale) => {
  return {
    type: languageConstants.SET_LOCALE,
    payload: locale,
  };
};

/**
 * @description запрос для переключателя языка
 * @param locale - загруженные переводы кэшируются по этому ключу в редьюсере
 * и не грузятся 100раз
 */
export const setLang = (locale) => (dispatch, getState) => {
  Cookies.set('LANG', locale);
  const { lang } = getState();
  const isMessagesLoaded = lang[locale];
  if (!isMessagesLoaded) {
    return dispatch(getTranslations()).then(() => dispatch(changeLang(locale)));
  } else {
    return dispatch(changeLang(locale));
  }
};
