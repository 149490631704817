import axios from 'axios';
import { getAuthToken, getCookieLang } from '../utils/utils';

import { handleResponseSuccess } from './helpers/handle-response';

export const req = axios.create();

req.defaults.baseURL = process.env.REACT_APP_BACKEND_API_PREFIX; // хост или пустота
req.defaults.withCredentials = true;
req.defaults.timeout = process.env.REACT_APP_FRONTEND_REQUEST_TIMEOUT;

// req.defaults.raxConfig = {
//   instance: req,
// };
/**
 * @description в каждый запрос записываются куки токена авторизации
 * и выбранного языка
 */
req.interceptors.request.use((request) => {
  request.headers[process.env.REACT_APP_AUTH_TOKEN_KEY] = getAuthToken();
  request.headers['LANG'] = getCookieLang();

  return request;
});

req.interceptors.response.use(
  (response) => {
    handleResponseSuccess(response);
    return Promise.resolve({
      data: response.data,
      status: response.status,
      statusText: response.statusText,
      error: response.data.error || '',
    });
  },
  (error) => {
    //handleResponseErrors(error);
    return Promise.reject(error);
  },
);
