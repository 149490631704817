import { req } from '../setup';

class ApiUserScedule {
  private key: string;
  private endPoint: string;
  private id: string | number;
  constructor() {
    this.key = '/interview';
    this.endPoint = '/interview-appointment';
    this.id = '';
  }

  getInterviewerSlots = async (query: any): Promise<any> => {
    return req
      .get(this.key + this.endPoint, { params: query })
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  getWorkShifts = async (query: any): Promise<any> => {
    return req
      .get('/shift', { params: query })
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  sendSelectedSlot = async (startDate: Date | string): Promise<any> => {
    return req
      .post(this.key + this.endPoint, { startDate })
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  deleteSelectedSlot = async (id: number): Promise<any> => {
    this.id = id;
    return req
      .delete(this.key + this.endPoint + '/' + this.id)
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };
}

const apiUserSchedule = new ApiUserScedule();

export default apiUserSchedule;
