import React, {
  // ChangeEven,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Divider,
  LinearProgress,
  Paper,
  // , Tabs, Tab
} from '@material-ui/core';

import ContactCard from '../components/ContactCard';
import DocumentListCard from '../components/DocumentListCard';
import { Files } from './EducationPage';

import { useIntl } from 'react-intl';
import { getContacts, getFiles } from '../../js/actions/cabinet';
import { IRootState } from './Cabinet';
import { Skeleton } from '@material-ui/lab';
// import FMessage from '../../rootComponents/FMessage/FMessage';
import Scheduler from '../components/Scheduler/Scheduler';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      maxHeight: 570,
      position: 'relative',
      display: 'flex',
      height: '100%',
      margin: 0,
      marginTop: 20,
      overflow: 'auto',
      width: '100%',
    },

    loadingIcon: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
    },
    container: {
      padding: 30,
      backgroundColor: 'white',
      position: 'relative',
      height: '100%',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        padding: 10,
      },
    },
    subHeader: {
      width: '100%',
      padding: '10px 0',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    documentBox: {
      maxHeight: 600,
      overflow: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 20,
    },
    subtitle: {
      margin: '10px 0',
    },
    divider: {
      margin: '20px 0 10px 0',
      width: '100%',
    },
    header: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    slash: {
      margin: '0 10px',
    },
    title: {
      display: 'flex',
      alignItems: 'center',
    },
    tab: {
      cursor: 'pointer',
    },
  }),
);

// interface RootStateI {
//   volunteer: VolunteerStateI;
// }

export interface Contact {
  id: number;
  lastName: string;
  firstName: string;
  patronymic: string;
  email: string;
  phoneNumber: string;
  photo: {
    url: string;
  };
}

const WorkPage = (): React.ReactElement => {
  const classes = useStyles();
  const { contacts, workFiles, loading } = useSelector(
    (state: IRootState) => state.cabinet,
  );
  const intl = useIntl();
  const dispatch = useDispatch();
  const [tab, setTab] = useState<string>('interview');

  const { commonFiles } = useSelector((state: IRootState) => state.cabinet);

  // const [value,
  //   //  setValue
  // ] = useState(0);

  const stateVolunteer = useSelector((state: IRootState) => state.volunteer);
  const isAuthenticated = stateVolunteer.isAuthenticated;
  const volunteerLoading = stateVolunteer.loading;

  const stateResume = useSelector((state: IRootState) => state.resume);
  const resumeLoading = stateResume.loading;

  const getFilesCB = useCallback(() => {
    dispatch(getFiles());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated && !commonFiles?.length) {
      getFilesCB();
    }
  }, [getFilesCB]);

  useEffect(() => {
    if (isAuthenticated && !contacts.length) {
      dispatch(getContacts());
    }
  }, []);

  const renderContacts = useMemo(() => {
    return contacts.map((contact: Contact, index: number) => {
      return (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
          <ContactCard contact={contact} />
        </Grid>
      );
    });
  }, [contacts]);

  const skeletonFileList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
    (elem: number) => {
      return (
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={elem}>
          <Skeleton height={90} />
          <Skeleton height={20} />
        </Grid>
      );
    },
  );

  // const handleChangeValue = useCallback(
  //   (_e: ChangeEvent<unknown>, newValue: number) => {
  //     setValue(newValue);
  //   },
  //   [setValue],
  // );

  const renderDocuments = useMemo(() => {
    return workFiles?.map((document: Files, index: number) => {
      return (
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={index}>
          <DocumentListCard document={document} key={index} />
        </Grid>
      );
    });
  }, [workFiles]);

  return (
    <>
      {/* <Divider light /> */}
      <Grid component={Paper} container className={classes.container}>
        {volunteerLoading && <LinearProgress className={classes.loadingIcon} />}
        <div className={classes.title}>
          <Typography
            variant='h5'
            color={tab === 'shift' ? 'textPrimary' : 'textSecondary'}
            className={classes.tab}
            onClick={() => setTab('shift')}
          >
            {`${intl
              .formatMessage({ id: 'page.work.title.work' })
              .toUpperCase()}`}
          </Typography>
          <Typography variant='h5' className={classes.slash}>
            /
          </Typography>
          <Typography
            variant='h5'
            className={classes.tab}
            onClick={() => setTab('interview')}
            color={tab === 'interview' ? 'textPrimary' : 'textSecondary'}
          >
            {intl
              .formatMessage({ id: 'page.work.title.interview' })
              .toUpperCase()}
          </Typography>
        </div>
        <Divider className={classes.divider} />

        <Scheduler tab={tab} />
        <div className={classes.subHeader}>{/* <ChangeDisplayIcon /> */}</div>
        <Grid
          item
          lg={12}
          container
          spacing={3}
          className={classes.documentBox}
        >
          {loading || volunteerLoading || resumeLoading
            ? skeletonFileList
            : renderDocuments}
        </Grid>
        <Typography variant='body2' className={classes.subtitle}>
          {intl.formatMessage({ id: 'page.work.subtitle.contacts' })}
        </Typography>
        <Divider className={classes.divider} />
        <Grid item lg={12} container spacing={2} className={classes.grid}>
          {renderContacts}
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(WorkPage);
