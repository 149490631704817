import { IntlShape } from 'react-intl';
/**
 * @description хэлпер для NODE_ENV = development
 * подсвечивает переводы, которые отсутствуют (только при рендере)
 * @param messages объект с ключами переводов
 * @param id айди нужного перевода
 * @param locale локаль - флаг - на каком языке исправлять
 */
export const checkUnknownTranslationWarn = (
  messages: IntlShape['messages'],
  id: string,
  locale: string,
): boolean => {
  if (!messages[id]) {
    if (process.env.NODE_ENV !== 'production') {
      console.log(`iFMessage => нет перевода для ключа - ${id} - ${locale}`);
    }
    return true;
  }
  return false;
};

type iFMessage = {
  values?: Record<string, string>;
  defaultText?: string;
};
/**
 * @description переводчик того, что не может в jsx
 * т.е. всякие нативные плейсхолдеры - короче это переводчик для JS функций и тд
 * в библиотеке просто этот метод (intl.formatMessage) длиннее - сократил его
 * @param intl объект который пробрасывается хуком injectIntl в компонент
 */
export const iFMessage = (intl: IntlShape) => (
  id: string,
  { values, defaultText }: iFMessage = {},
): string => {
  const dm = intl.messages;
  if (checkUnknownTranslationWarn(dm, id, intl.locale)) {
    return defaultText ? defaultText : '';
  } else {
    return intl.formatMessage(
      {
        id,
        defaultMessage: dm[id],
      },
      values,
    );
  }
};

// export const getMessage = injectIntl ((props: any): string => {
//   const dm = props.intl.messages;
//   if (checkUnknownTranslationWarn(dm, props.id, props.intl.locale)) {
//     return props.defaultText ? props.defaultText : '';
//   } else {
//     return props.intl.formatMessage(
//       {
//         id: props.id,
//         defaultMessage: dm[props.id],
//       },
//       props.values,
//     );
//   }
// });

export const getFileExt = (fileName: string): string | null => {
  const mtch = fileName.match(/.*(\..*)/i);
  if (mtch && mtch[1]) {
    return mtch[1];
  }
  return null;
};

export const convertMBytestoBytes = (mb: number): number => mb * (1024 * 1024);

const PREFIX = process.env.REACT_APP_BACKEND_API_PREFIX;

const defaultImageUrl =
  'https://i03.fotocdn.net/s120/24899c63b556edb5/public_pin_m/2752889523.jpg';

export const getImgUrlForNews = (url: string) => {
  if (!url || url?.length < 4) {
    return defaultImageUrl;
  } else if (url.slice(0, 4) === 'http') {
    return url;
  } else if (url.slice(0, 10) === '/newsphoto') {
    return `${PREFIX}/static${url}`;
  }
};
