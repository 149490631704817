export const cabinetConstants = {
  SET_TAB_VALUE: 'SET_TAB_VALUE',
  SET_NEWS_TAB_VALUE: 'SET_NEWS_TAB_VALUE',
  MERGE_NEWS_TO_ALL: 'MERGE_NEWS_TO_ALL',

  GET_NEWS_FETCHING: 'GET_NEWS_FETCHING',
  GET_NEWS_SUCCESS: 'GET_NEWS_SUCCESS',
  GET_NEWS_FAILURE: 'GET_NEWS_FAILURE',

  GET_UNREADED_PERSONAL_NEWS_FETCHING: 'GET_UNREADED_PERSONAL_NEWS_FETCHING',
  GET_UNREADED_PERSONAL_NEWS_SUCCESS: 'GET_UNREADED_PERSONAL_NEWS_SUCCESS',
  GET_UNREADED_PERSONAL_NEWS_FAILURE: 'GET_UNREADED_PERSONAL_NEWS_FAILURE',

  READ_NEWS_FETCHING: 'READ_NEWS_FETCHING',
  READ_NEWS_SUCCESS: 'READ_NEWS_SUCCESS',
  READ_NEWS_FAILURE: 'READ_NEWS_FAILURE',

  GET_ALL_NEWS_FETCHING: 'GET_ALL_NEWS_FETCHING',
  GET_ALL_NEWS_SUCCESS: 'GET_ALL_NEWS_SUCCESS',
  GET_ALL_NEWS_FAILURE: 'GET_ALL_NEWS_FAILURE',

  GET_PERSONAL_NEWS_FETCHING: 'GET_PERSONAL_NEWS_FETCHING',
  GET_PERSONAL_NEWS_SUCCESS: 'GET_PERSONAL_NEWS_SUCCESS',
  GET_PERSONAL_NEWS_FAILURE: 'GET_PERSONAL_NEWS_FAILURE',

  GET_PERSONAL_NEWS_FILTER_FETCHING: 'GET_PERSONAL_NEWS_FILTER_FETCHING',
  GET_PERSONAL_NEWS_FILTER_SUCCESS: 'GET_PERSONAL_NEWS_FILTER_SUCCESS',
  GET_PERSONAL_NEWS_FILTER_FAILURE: 'GET_PERSONAL_NEWS_FILTER_FAILURE',

  GET_ALERTS_FETCHING: 'GET_ALERTS_FETCHING',
  GET_ALERTS_SUCCESS: 'GET_ALERTS_SUCCESS',
  GET_ALERTS_FAILURE: 'GET_ALERTS_FAILURE',

  READ_ALERT_FETCHING: 'READ_ALERT_FETCHING',
  READ_ALERT_SUCCESS: 'READ_ALERT_SUCCESS',
  READ_ALERT_FAILURE: 'READ_ALERT_FAILURE',

  DELETE_ALERT_FETCHING: 'DELETE_ALERT_FETCHING',
  DELETE_ALERT_SUCCESS: 'DELETE_ALERT_SUCCESS',
  DELETE_ALERT_FAILURE: 'DELETE_ALERT_FAILURE',

  SET_PERSONAL_NEWS: 'SET_PERSONAL_NEWS',
  GO_TO_LOGIN: 'GO_TO_LOGIN',
  SWITCH_PROFILE_SETTING: 'SWITCH_PROFILE_SETTING',

  GET_COMMON_FILES_FETCHING: 'GET_COMMON_FILES_FETCHING',
  GET_COMMON_FILES_SUCCESS: 'GET_COMMON_FILES_SUCCESS',
  GET_COMMON_FILES_FAILURE: 'GET_COMMON_FILES_FAILURE',

  GET_SPECIAL_FILES_FETCHING: 'GET_SPECIAL_FILES_FETCHING',
  GET_SPECIAL_FILES_SUCCESS: 'GET_SPECIAL_FILES_SUCCESS',
  GET_SPECIAL_FILES_FAILURE: 'GET_SPECIAL_FILES_FAILURE',

  GET_WORK_FILES_SUCCESS: 'GET_WORK_FILES_SUCCESS',
  GET_CONTACTS_SUCCESS: 'GET_CONTACTS_SUCCESS',

  GET_TEST_FETCHING: 'GET_TEST_FETCHING',
  GET_TEST_SUCCESS: 'GET_TEST_SUCCESS',
  GET_TEST_FAILURE: 'GET_TEST_FAILURE',

  GET_TESTS_SUMMARY_FETCHING: 'GET_TESTS_SUMMARY_FETCHING',
  GET_TESTS_SUMMARY_SUCCESS: 'GET_TESTS_SUMMARY_SUCCESS',
  GET_TESTS_SUMMARY_FAILURE: 'GET_TESTS_SUMMARY_FAILURE',

  SET_PROFILE_SETTINGS_FETCHING: 'SET_PROFILE_SETTINGS_FETCHING',
  SET_PROFILE_SETTINGS_SUCCESS: 'SET_PROFILE_SETTINGS_SUCCESS',
  SET_PROFILE_SETTINGS_FAILURE: 'SET_PROFILE_SETTINGS_FAILURE',

  GET_EMPLOYEE_DATA_FETCHING: 'GET_EMPLOYEE_DATA_FETCHING',
  GET_EMPLOYEE_DATA_SUCCESS: 'GET_EMPLOYEE_DATA_SUCCESS',
  GET_EMPLOYEE_DATA_FAILURE: 'GET_EMPLOYEE_DATA_FAILURE',
  SET_SCREEN_SIZE: 'SET_SCREEN_SIZE',

  SET_CALENDAR_TAB_VALUE: 'SET_CALENDAR_TAB_VALUE',
};
