import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { string, node } from 'prop-types';
import FMessage from '../../../../rootComponents/FMessage/FMessage';
import { Grid } from '@material-ui/core';
// TODO: переписать на TS
class Layout extends Component {
  static propTypes = {
    children: node.isRequired,
    formattedTitle: string.isRequired,
    className: string,
    direction: string,
  };

  static defaultProps = {
    className: '',
    direction: 'row',
  };
  /**
   * @description <Helmet title={title} /> - название табы браузера
   */
  render() {
    const { children, formattedTitle, className, direction } = this.props;
    return (
      <Grid container className={className} direction={direction}>
        {formattedTitle && (
          <FMessage id={formattedTitle}>
            {([title]) => <Helmet title={title} />}
          </FMessage>
        )}
        {children}
      </Grid>
    );
  }
}

export default Layout;
