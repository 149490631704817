import React from 'react';
import PrivateRoute from '../router/PrivateRoute';
import PublicRoute from '../router/PublicRoute';
import routesConfig from './routes-config';
// TODO: переписать на TS
const getRouteComponent = (type) => {
  switch (type) {
    case 'public':
      return PublicRoute;
    case 'private':
      return PrivateRoute;
    default:
      return PublicRoute;
  }
};

const routes = (store) =>
  routesConfig.map(({ type, ...rest }, index) => {
    const RouteComponent = getRouteComponent(type);
    return <RouteComponent {...rest} store={store} key={index} />;
  });

export default routes;
