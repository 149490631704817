/* eslint no-useless-escape: 'off' */
import Joi from 'joi';
import { stringify } from 'query-string';
import Cookies from 'js-cookie';

export const trim = (v) => (typeof v === 'string' ? v.trim() : v);

export const isNil = (prop) =>
  prop === null ||
  prop === undefined ||
  (Array.isArray(prop) && prop.length === 0);
/**
 * @description отфильтровать объект
 * @param fields исключаемые поля
 */
export const omit = (obj = {}, fields) =>
  Object.keys(obj)
    .filter((key) => fields.indexOf(key) < 0)
    .reduce((newObj, key) => Object.assign(newObj, { [key]: obj[key] }), {});
/**
 *  @description взять только нужные поля
 * @param fields включаемые поля
 */
export const pick = (obj = {}, fields) =>
  Object.keys(obj)
    .filter((key) => fields.indexOf(key) >= 0)
    .reduce((newObj, key) => Object.assign(newObj, { [key]: obj[key] }), {});
/**
 *  @description копипаст аналога функции lodash.get
 * как работет смотрите в инете
 */
export const get = (obj, path, defaultValue = undefined) => {
  const travel = (regexp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj,
      );
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};
//TODO: проверить правильность работы createReduxFormValidation
/**
 *  @description копипаст обертки с небольшими фиксами
 * - функция для валидации при вводе и сабмите формы
 * это враппер над объектом полей валидации в функции инициализации формы
 * чутка сокращается код
 * @returns объект с ошибками
 */
export const createReduxFormValidation = (schema) => (values) => {
  const { error } = Joi.object()
    .keys(schema)
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(values);

  if (error) {
    return error.details.reduce((result, { path, message }) => {
      const step = path[0];

      if (path.length >= 3) {
        const index = path[1];

        if (!result[step]) {
          result[step] = [];
        }
        if (result[step][index] === undefined) {
          for (let i = result[step].length; i <= index; i++) {
            result[step].push({});
          }
        }
        result[step][index][path[2]] = message;
      } else {
        result[step] = message;
      }
      return result;
    }, {});
  }

  return {};
};

export const getAuthToken = () =>
  Cookies.get(process.env.REACT_APP_AUTH_TOKEN_KEY);

export const getCookieLang = () => Cookies.get('LANG');

export const setAuthToken = (value, options = {}) =>
  Cookies.set(
    process.env.REACT_APP_AUTH_TOKEN_KEY,
    `${process.env.REACT_APP_AUTH_TOKEN_KEY_PREFIX}${value}`,
    options,
  );

export const deleteAuthToken = () =>
  Cookies.remove(process.env.REACT_APP_AUTH_TOKEN_KEY);
/**
 * @param params объект который запихивается в урлу в params (то что после ? идет)
 */
export const setSearchParams = (params = {}) => {
  window.history.replaceState(
    {},
    null,
    `${window.location.pathname}?${stringify(params, { encode: false })}`,
  );
};

export const months = [
  'янв',
  'фев',
  'мар',
  'апр',
  'май',
  'июн',
  'июл',
  'авг',
  'сен',
  'окт',
  'ноя',
  'дек',
];
export const monthsEn = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sen',
  'okt',
  'nov',
  'dec',
];

export const convertDate = (dateString, locale) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month =
    locale === 'EN' ? monthsEn[date.getMonth()] : months[date.getMonth()];
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const today = new Date();
  const isToday =
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate();

  const wordToday = locale === 'EN' ? 'Today' : 'Сегодня';

  const strMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return isToday
    ? `${wordToday}, ${hours}:${strMinutes}`
    : [day, month, year].join(' ');
};
