import { get } from '@utils/utils';
import clone from 'clone';
import {
  saveIdAsObj,
  formatToType,
  fillEmptyRequiredObjFields,
} from './formatters';
import {
  numberFields,
  objInArrayNumberFields,
  stringFields,
  objInArrayStringFields,
  objInArrayNotEmpty,
} from './resumeFormattedFields';
// TODO: ЕСЛИ ВСЕ ЧЕТКО БУДЕТ РАБОТАТЬ ТО ЛУЧШЕ УДАЛИТЬ
// "clone": "2.1.2",
// "deep-object-diff": "1.1.0",
export const normalizeResumeOnSave = (data) => {
  /**
   * @description нужно для диффа - чтобы была видна разница
   * @function clone
   */
  let steps = clone(data);
  // ============== medicalRestrictions =============
  if (steps?.medicalRestrictions?.length) {
    steps.medicalRestrictions = steps.medicalRestrictions
      .map((el) => +el)
      .filter((el) => el);
  }
  // ================================================
  const haveVolunteerExperience = get(steps, 'haveVolunteerExperience');
  if (haveVolunteerExperience === 'true') {
    steps.haveVolunteerExperience = true;
  }
  if (haveVolunteerExperience === 'false') {
    steps.haveVolunteerExperience = false;
  }

  const isMemberOfPrefessionals = get(steps, 'isMemberOfPrefessionals');
  if (isMemberOfPrefessionals === 'true') {
    steps.isMemberOfPrefessionals = true;
  }
  if (isMemberOfPrefessionals === 'false') {
    steps.isMemberOfPrefessionals = false;
  }

  const isMemberOfStudSquad = get(steps, 'isMemberOfStudSquad');
  if (isMemberOfStudSquad === 'true') {
    steps.isMemberOfStudSquad = true;
  }
  if (isMemberOfStudSquad === 'false') {
    steps.isMemberOfStudSquad = false;
  }

  const isMemberOfMovementFirst = get(steps, 'isMemberOfMovementFirst');
  if (isMemberOfMovementFirst === 'true') {
    steps.isMemberOfMovementFirst = true;
  }
  if (isMemberOfMovementFirst === 'false') {
    steps.isMemberOfMovementFirst = false;
  }

  const isInVolunteerCenter = get(steps, 'isInVolunteerCenter');
  if (isInVolunteerCenter === 'true') {
    steps.isInVolunteerCenter = true;
  }
  if (isInVolunteerCenter === 'false') {
    steps.isInVolunteerCenter = false;
  }

  const haveTeamleadExperience = get(steps, 'haveTeamleadExperience');
  if (haveTeamleadExperience === 'true') {
    steps.haveTeamleadExperience = true;
  }
  if (haveTeamleadExperience === 'false') {
    steps.haveTeamleadExperience = false;
  }

  const isMedicialRestrictions = get(steps, 'isMedicialRestrictions');
  if (isMedicialRestrictions === 'true') {
    steps.isMedicialRestrictions = true;
  }
  if (isMedicialRestrictions === 'false') {
    steps.isMedicialRestrictions = false;
  }
  // ================================================
  const clothesSize = get(steps, 'clothesSize');
  steps.clothesSize = {
    id: clothesSize,
  };
  // ================================================
  if (
    steps?.specialWorkingConditions &&
    !steps?.specialWorkingConditionsDescription?.trim()
  ) {
    steps.specialWorkingConditions = false;
    steps.specialWorkingConditionsDescription = '';
  }
  // ============= function =========================
  const additionalFunctionTerms = get(steps, 'additionalFunctionTerms');
  if (additionalFunctionTerms === 'true') {
    steps.additionalFunctionTerms = true;
  }
  if (additionalFunctionTerms === 'false') {
    steps.additionalFunctionTerms = false;
  }

  const desiredFunction = get(steps, 'application[0].desiredFunction');
  if (desiredFunction) {
    steps.application[0].desiredFunction = desiredFunction
      .map((functionId, order) => {
        if (functionId) {
          return {
            functionId,
            order,
          };
        } else {
          return null;
        }
      })
      .filter(Boolean);
  }
  // =================================================
  // ============== languages =============
  const languages = get(steps, 'languages');
  if (languages?.length) {
    languages.forEach((el, index) => {
      if (el.lang) {
        steps.languages[index]['lang'] = saveIdAsObj(el.lang);
        steps.languages[index]['level'] = saveIdAsObj(el.level);
        steps.languages[index]['order'] = index;
      }
      return el;
    });
  }
  // ============== experience =============
  const experience = get(steps, 'experience');
  if (experience?.length) {
    experience.forEach((el, index) => {
      if (!el.fieldOfActivity) {
        steps.experience[index]['fieldOfActivity'] = '';
      }
      if (!el.proffExp) {
        steps.experience[index]['proffExp'] = '';
      }
      return el;
    });
  }

  // ================================================

  if (steps.registrationAddrCity) {
    steps.registrationAddrRegion = steps.registrationAddrCity.region;
    steps.registrationAddrCountry = steps.registrationAddrCity.country;
  }
  if (steps.factAddrCity) {
    steps.factAddrRegion = steps.factAddrCity.region;
    steps.factAddrCountry = steps.factAddrCity.country;
  }
  // const citizenship = get(steps, 'citizenship');
  // if (typeof citizenship === 'number') {
  //   steps.citizenship = { id: citizenship };
  // }
  // const region = get(steps, 'region');
  // if (typeof region === 'number') {
  //   steps.region = { id: region };
  // }

  // ================================================
  const desiredPosition = get(steps, 'application[0].desiredPosition');
  if (desiredPosition) {
    steps.application[0].desiredPosition = desiredPosition
      .map(saveIdAsObj)
      .filter((el) => el);
  }
  // =================================================
  const photo = get(steps, 'photo');
  if (photo) {
    if (typeof photo === 'string' && photo.includes(';base64,')) {
      steps.photo = photo;
    } else {
      steps.photo = `/${photo.split('/').slice(3).join('/')}`;
    }
  }

  // ==================== clear ====================
  [
    'volunteerExperience',
    'teamleadExperience',
    'education',
    'experience',
    'languages',
  ].forEach((expKey) => {
    if (steps[expKey]) {
      steps[expKey] = steps[expKey].filter((exp) => !!Object.keys(exp).length);
    }
  });
  // =================================================

  steps = {
    ...formatToType('number', steps, numberFields),
    ...formatToType('number', steps, objInArrayNumberFields, true),
  };

  steps = {
    ...formatToType('trim', steps, stringFields),
    ...formatToType('trim', steps, objInArrayStringFields, true),
  };

  steps = fillEmptyRequiredObjFields(steps, objInArrayNotEmpty);
  // ==================== clear ====================
  Object.keys(steps).forEach((key) => {
    if (steps[key] === null) {
      delete steps[key];
    }
  });
  const application = get(steps, 'application');
  if (application?.length) {
    application.forEach((app, index) => {
      Object.keys(app).forEach((key) => {
        if (app[key] === null) {
          delete app[key];
        }
      });
      application[index] = app;
    });
  }
  /**
   * @description Дифф того что было и того что стало
   */

  //console.log('OnSave', diff(data, steps), { data, steps });
  return steps;
};
