import React, { FC } from 'react';
import cn from 'classnames';
import { ButtonProps } from '@material-ui/core/Button';
import Btn from './Btn';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: 48,
    fontWeight: 'bold',
    fontSize: 16,
  },
}));

const BigRoundedBtn: FC<ButtonProps> = ({ className, ...props }) => {
  const classes = useStyles();
  return (
    <Btn
      {...props}
      className={cn(classes.root, className)}
      variant='contained'
    />
  );
};

export default BigRoundedBtn;
