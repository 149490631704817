// import { Files } from '../../../volunteers/pages/EducationPage'; // adel
import {req} from '../setup';

class ApiUserFiles {
  private key: string;
  constructor() {
    this.key = '/edu-file/file';
  }

  getFilesList = async () => {
    return req
      .get(this.key)
      .then((res) => {
        return res.data;
      })
      .catch((err) => console.log(err));
  };
}

const apiUserFiles = new ApiUserFiles();

export default apiUserFiles;
