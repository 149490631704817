import React, { FC, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import FMessage from '../../rootComponents/FMessage/FMessage';
import BtnGradient from '../../rootComponents/Btn/BtnGradient';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from '../../rootComponents/Loader/Loader';
import LangSelector from '../../js/components/common/LangSelector/LangSelector';
import Logo from '../../images/main/logo_black.svg';
// import EuroLogo from '../../images/main/euroskills.svg'; //adel
import { Button, Typography } from '@material-ui/core';
import CenterNotification from '../../rootComponents/CenterNotification/CenterNotification';
import { useLoginStyles } from '../../styles/jss/loginStyles';
import classNames from 'classnames';

declare global {
  interface Window {
    chrome: any;
  }
  interface Document {
    documentMode?: any;
  }
}

const HomePage: FC = () => {
  // Internet Explorer 6-11
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;
  const [modalOpened, setModalOpened] = useState(false);

  useEffect(() => {
    const setOpenModal = () => {
      setModalOpened(isIE);
    };
    setOpenModal();
  }, [isIE]);

  const classes = useLoginStyles();
  const history = useHistory();
  const { loading } = useSelector((state: any) => state.volunteer);
  const handleClick = (path: string) => history.push(path);

  const handleCloseModal = () => {
    setModalOpened(false);
  };
  return (
    <div className={classes.container}>
      {loading && <Loader />}
      <div className={classes.wrapper}>
        <div className={classes.formBox}>
          <Grid item className={classes.header}>
            <img src={Logo} alt='logo' className={classes.logo} />
          </Grid>
          <Grid item>
            <Typography
              variant='h3'
              className={classNames(classes.mainText, classes.textContainer)}
            >
              <FMessage id='pages.home.title.text' />
            </Typography>
          </Grid>
          <Grid item className={classes.btnContainer}>
            <div>
              <BtnGradient
                onClick={handleClick.bind(null, '/login')}
                className={classes.homeBtns}
              >
                <FMessage id='pages.home.btn.login' />
              </BtnGradient>
            </div>
            <div>
              <Button
                variant='contained'
                size='large'
                onClick={handleClick.bind(null, '/register')}
                className={classes.homeBtns}
              >
                <FMessage id='pages.home.btn.register' />
              </Button>
            </div>
          </Grid>
        </div>
      </div>
      {isIE && (
        <CenterNotification
          open={modalOpened}
          title={<FMessage id={'pages.home.old-browser-modal.title'} />} //'Устаревший браузер!'
          message={<FMessage id={'pages.home.old-browser-modal.message'} />} ////'В данном браузере сайт может отображаться некорректно'
          message2={<FMessage id={'pages.home.old-browser-modal.message2'} />} // Пожалуйста, используйте современный браузер
          close={handleCloseModal}
        />
      )}
    </div>
  );
};

export default HomePage;
