import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { removeSnackbar } from '../../js/actions/notifications';
import { useIntl } from 'react-intl';
// TODO: переписать на TS
let displayed = [];

/**
 * @description копипаста из инета
 */
const Notifier = () => {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notifications || []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const intl = useIntl();
  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };
  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };
  React.useEffect(() => {
    notifications.forEach(
      ({ key, message, options = {}, dismissed = false }) => {
        const validKey = message?.split(' ').join('.');
        const renderMessage = intl?.messages?.[validKey]
          ? intl.formatMessage({ id: validKey })
          : message;
        if (dismissed) {
          closeSnackbar(key);
          return;
        }
        if (displayed.includes(key)) return;
        // enqueueSnackbar - добавляет в стэк алерты = показывает их
        enqueueSnackbar(renderMessage, {
          key,
          ...options,
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey);
            }
          },
          onExited: (_event, myKey) => {
            dispatch(removeSnackbar(myKey));
            removeDisplayed(myKey);
          },
        });
        storeDisplayed(key);
      },
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch, intl]);
  return null;
};

export default React.memo(Notifier);
