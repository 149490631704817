import React, { FC } from 'react';
import cn from 'classnames';
import { ButtonProps } from '@material-ui/core/Button';
import BigRoundedBtn from './BigRoundedBtn';
import { makeStyles } from '@material-ui/core/styles';
// TODO: брать цвета из сделанной палитры - цвета в Фигме
const useStyles = makeStyles(() => ({
  root: {
    background: 'black',
    color: 'white',
    border: '3px solid black',
    borderRadius: 0,
  },
}));

const BtnGradient: FC<ButtonProps> = ({ className, ...props }) => {
  const classes = useStyles();
  return (
    <BigRoundedBtn
      {...props}
      className={cn(classes.root, className)}
      color='primary'
    />
  );
};

export default BtnGradient;
