import messages_en from '../translations/en.json';
import messages_ru from '../translations/ru.json';
// TODO: вынести в \src\constants\lang.ts
export const messages = {
  RU: messages_ru,
  EN: messages_en,
};

export const defaultLocale =
  process.env.REACT_APP_FRONTEND_DEFAULT_LANGUAGE || 'RU';
/**
 * @description для резьюсера и переключалки
 */
export const defaultLanguages = [
  { key: 'RU', title: 'Русский' },
  { key: 'EN', title: 'English' },
];
