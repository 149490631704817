import { history } from '../store/configureStore';
import { cabinetConstants } from '../constants/cabinet';
/**
 * @description переходим на стр логина после успешной установки пароля
 */
const setNewPassMiddleware = () => (next) => (action) => {
  const result = next(action);
  if (
    //action.type === volunteerConstants.SET_NEW_PASS_SUCCESS ||
    action.type === cabinetConstants.GO_TO_LOGIN
  ) {
    history.push('/login');
  }
  return result;
};

export default setNewPassMiddleware;
