import {Box, Hidden, makeStyles} from "@material-ui/core";
import React from "react";
import {useSelector} from "react-redux";
import FMessage from "../../rootComponents/FMessage/FMessage";
import {IRootState} from "../pages/Cabinet";

const useStyles = makeStyles((_theme) => ({
  link: {
    color: '#cecece',
    fontSize: '1.25rem',
    marginTop: '20px',
    '&:hover': {
      color: 'white',
      textDecoration: 'none'
    }
  }
}));

interface IProps {
  embedId?: string,
  closeViewer?: () => void
}

const YoutubeEmbed: React.FC<IProps> = ({embedId, closeViewer}) => {
  const classes = useStyles()
  const {width} = useSelector((state: IRootState) => state.cabinet.screenSize)


  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <iframe
        height={width > 800 ? '487' : `${width / 1.7 - 20}`}//'390',
        width={width > 800 ? '800' : `${width - 20}`}//'640',
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
      <Hidden mdUp>

        <a
          href={`https://www.youtube.com/watch?v=${embedId}`}
          onClick={closeViewer}
          target='blank'
          rel='noopener noreferrer'
          className={classes.link}>
          <FMessage id={'cabinet.edu-files.player'} />
        </a>
      </Hidden>


    </Box>
  )
};

export default YoutubeEmbed;
