import React, { FC, ReactElement } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { Add, Remove } from '@material-ui/icons';

export const questions = {
  resumeQuestions: [
    {
      question:
        'До какого числа я могу подать заявку в волонтерскую программу\n' +
        'Финала Чемпионата «Профессионалы» 2024?',
      answer:
        'Заявки от кандидатов в волонтеры принимаются до 10 октября 2024 года.',
    },
    {
      question:
        'Когда произойдет распределение волонтеров по функциональным направлениям?',
      answer:
        'В ноябре 2024 г. После оглашения результатов отбора, всем волонтерам\n' +
        'будет предложена конкретная позиция. Обращаем ваше внимание, что\n' +
        'предложенная позиция может отличаться от выбранной вами при подаче\n' +
        'заявки.',
    },
    {
      question:
        'Что делать, если я допустил ошибку в анкете, а возможность\n' +
        'редактирования заблокирована?',
      answer:
        'Вам необходимо связаться с Волонтерским центром или написать на\n' +
        'почту с указанием ФИО и телефона для обратной связи. Мы обязательно\n' +
        'свяжемся с Вами и все исправим. Контакты приведены ниже на странице.',
    },
  ],

  registrationQuestions: [
    {
      question:
        'Могу ли я подать заявку на право стать волонтером , если мне еще нет 18 лет?',
      answer:
        'Да, можете. Большинство позиций допускают участие волонтеров с 16 лет на\n' +
        'момент подписания документов. Ограничения по возрасту представлены в\n' +
        'разделе с описанием волонтёрских вакансий на нашем сайте.',
    },
    {
      question:
        'Я живу в другом городе, могу ли принять участие в отборе волонтеров?',
      answer:
        'Да, вы можете подать заявку и принять участие в отборе кандидатов в\n' +
        'волонтеры, подав заявку в электронном личном кабинете волонтера.\n' +
        'Организатор не покрывает расходы на дорогу и проживание.',
    },
  ],

  interviewQuestions: [
    {
      question: 'Как будет проходить отбор волонтеров?',
      answer:
        'Все заявки от кандидатов будут обработаны и проверены на корректность\n' +
        'заполнения. Далее все кандидаты пройдут интервью (очно или по телефону).\n' +
        'Результаты отбора придут на электронные адреса кандидатов после 1 ноября\n' +
        '2024 года. Далее кандидатам, которые успешно прошли отбор, необходимо\n' +
        'предложено пройти очное и онлайн-обучение, выполнить тестирование по\n' +
        'пройденному материалу.',
    },
    {
      question:
        'Мне будет автоматически назначена позиция или я могу выбрать функциональное направление?',
      answer:
        'При заполнении заявки вы сможете указать 3 предпочтительных\n' +
        'направления. Мы обязательно учтем ваши пожелания при распределении.\n' +
        'Однако, обращаем ваше внимание, что при распределении волонтеров на\n' +
        'позиции ваше функциональное направление может измениться на\n' +
        'усмотрение рекрутера.'
    },
  ],
  forumQuestions: [
    {
      question:
        'Очень хочу стать волонтером Финала Чемпионата «Профессионалы» 2024, но не знаю английский язык, могу ли я подать заявку?',
      answer:
        'Да! У нас есть множество позиций, где знание иностранного языка не является обязательным условием. А есть и те, где хороший уровень владения английским очень важен. Обязательно укажи в анкете все иностранные языки, которые ты знаешь.',
    },
    {
      question: 'Что нужно будет делать в качестве волонтера?',
      answer:
      'Задачи волонтера будут сформированы в зависимости от назначенной роли. Волонтерская программа включает в себя 13 функциональных направлений деятельности, с их описанием можно ознакомиться на нашем сайте.',
    },
    {
      question: 'Получают ли волонтеры за свою помощь деньги?',
      answer:
        'Добровольческая деятельность не предполагает оплату за оказание помощи в\n' +
        'проведении мероприятия, но волонтеры получают фирменную экипировку,\n' +
        'мерч, питание и благодарственные письма.',
    },
    {
      question: 'В какой период будут задействованы волонтеры?',
      answer:
        'График волонтера будет составлен в зависимости от занимаемой позиции.\n' +
        'Деятельность одних функциональных направлений подразумевает занятость\n' +
        'до месяца, других - до нескольких смен. Первые волонтеры выходят на\n' +
        'смены на комплектование униформы и сувенирной продукции – в середине\n' +
        'ноября.'
    },
    {
      question: 'Смогут ли волонтеры посещать соревнования?',
      answer:
        'Соревновательные площадки смогут посещать только волонтеры\n' +
        'функционального направления "Ассистенты соревновательных площадок" и\n' +
        '«Экскурсионная программа», остальные категории волонтеров могут быть\n' +
        'зрителями соревнований за пределами соревновательной площадки в\n' +
        'свободное время от смен.'
    },
    {
      question: 'Что делать, если я передумал участвовать?',
      answer: 'Очень жаль, но такое случается. В этом случае вам нужно как можно\n' +
        'скорее сообщить об отказе своему координатору в волонтерский центр,\n' +
        'заполнить документы об отказе и вернуть сувенирную продукцию и\n' +
        'форму.'
    },
    {
      question: 'Я не нашел здесь своего вопроса.',
      answer: 'Задайте свой вопрос по электронной почте volunteers@ossp.spb.ru',
    },
  ],
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 700,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }),
);

interface IQuestions {
  question: string;
  answer: string;
}

interface IFaqProps {
  questions: IQuestions[];
}

const FormControlLabel = withStyles({
  label: {
    fontWeight: 'bold',
  },
})(MuiFormControlLabel);

const Faq: FC<IFaqProps> = ({ questions }): ReactElement => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (
    _event: React.ChangeEvent<unknown>,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className={classes.root}>
      {questions.map(({ answer, question }, index) => {
        return (
          <Accordion
            key={question}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            elevation={0}
          >
            <AccordionSummary
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
            >
              <FormControlLabel
                aria-label='Acknowledge'
                control={
                  expanded !== `panel${index}` ? (
                    <Add
                      color='primary'
                      fontSize='large'
                      style={{ marginRight: 10 }}
                    />
                  ) : (
                    <Remove
                      color='primary'
                      fontSize='large'
                      style={{ marginRight: 10 }}
                    />
                  )
                }
                label={question}
              />
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{answer}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default Faq;
